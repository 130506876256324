import styles from './ZoneDetails.module.css';
import helpers from '../../helpers.js';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../UserContext.js';
import Button from "../Button.js";
import Overlay from "../Overlay.js";

import Module from "../Module.js";
import TextInput from "../TextInput.js";
import Selector from "../../components/Selector";
import Checkbox from '../Checkbox.js';
import ApplicationCard from './ApplicationCard.js';

// const soilTypeData = [
//   { name: "-", value: "" },
//   { name: "Clay", value: "clay" },
//   { name: "Sandy", value: "sandy" },
//   { name: "Loamy", value: "loamy" },
//   { name: "Other", value: "other" }
// ];

const fieldTypeData = [
  { name: "Arable", value: "arable" },
  { name: "Grassland", value: "grassland" },
  { name: "Woodland", value: "woodland" },
  { name: "Other", value: "other" }
];

function ZoneDetails(props) {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({});
  const [editingInfo, setEditingInfo] = useState(false);
  const [displayDeleteOverlay, setDisplayDeleteOverlay] = useState(false);


  
  const handleChange = (formField, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [formField]: formField === "surfaceArea" && value !== "" && isNaN(value) 
        ? prevData.surfaceArea // Keep previous valid value if input is invalid
        : value, // Otherwise, store new value
    }));
  };

  const renderOverlay = () => {
    if(!displayDeleteOverlay){
      return;
    }
    return(
      <Overlay>
        <div className={styles.deleteZoneContainer}>
           <h2>Delete Zone?</h2>
          <p>Are you sure you want to delete this zone? Type "<b>{props.selectedZone.zone_name}</b>" to delete zone</p>
          <div className={styles.deleteInput}>
            <TextInput 
              name="Zone Name" 
              value={formData.overlayInput} 
              onChange={(value) => handleChange("overlayInput", value)} 
              labelStyle="top" 
              placeholder="Enter Zone Name"
            />
            </div>
          <Button name={"Delete Zone"} disabled={props.selectedZone.zone_name === formData.overlayInput ? false : true} color={"Error"} onPress={() => deleteZone()}/>
          <Button name={"Cancel"}  color={"Coal"} onPress={() => setDisplayDeleteOverlay(false)}/>
        </div>
      </Overlay>
    ) 
  }

  // Sample data (Replace with actual API response)
  const zoneData = {
    zoneName: "Moorsgate Field",
    fieldId: "FI-1286",
    area: 6.92,
    biochar: 3.65,
    fixedCarbon: 3.39,
    co2Removal: 9.83,
    applications: [
      // { id: "A-000001", date: "11-01-2025", method: "Direct-to-Land", tags: ["#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428"] },
      // { id: "A-000012", date: "13-02-2025", method: "Direct-to-Land", tags: ["#CHA-002428", "#CHA-002428"] },
      // { id: "A-000021", date: "01-03-2025", method: "Direct-to-Land", tags: ["#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428"] },
      // { id: "A-000046", date: "26-03-2025", method: "Direct-to-Land", tags: ["#CHA-002428", "#CHA-002428", "#CHA-002428", "#CHA-002428"] },
    ],
  };

  useEffect(() => {
    if(props.selectedZone){
      setFormData({
        zoneName: props.selectedZone.zone_name,
        zoneId: props.selectedZone.zone_id,
        fieldId: props.selectedZone.field_id,
        fieldName: props.selectedZone.field_name,
        surfaceArea: props.selectedZone.surface_area,
        soilType: props.selectedZone.soil_type,
        fieldType: props.selectedZone.field_type,
      });
    } else {
      setFormData({
        zoneName: "",
        zoneId: "",
        fieldId: "",
        fieldName: "",
        surfaceArea: "",
        soilType: "",
        fieldType: "",
      });
    }
  
  },[props.selectedZone]) 


  const handleSubmit = async () => {
    try {
      // Validate required fields
      if (!user.id || !formData.zoneName) {
        // return helpers.errorPrompt("Zone Name is required.", null, setPrompt, null);
      }

      let finalCenterPoint = null;
      let finalPolygonShape = null;


      // 🔹 Construct request body to match backend schema
      const requestBody = {
        _user: user.details.id,
        zone_name: formData.zoneName,
        field_name: formData.fieldName || formData.zoneName, // Default to zone name if empty
        field_id: formData.fieldId,
        surface_area: formData.surfaceArea || null,
        soil_type: formData.soilType || null,
        field_type: formData.fieldType || null,
      };

      const request = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        body: JSON.stringify(requestBody),
      };

      // Send request
      const response = await fetch(user.backEndURL + "/zones/" + props.selectedZone._id, request);
      const jsonResponse = await response.json();

      if (jsonResponse.success) {
        props.reloadZones();
      } else {
        alert("Oops! Something went wrong while updating the zone.");
        // helpers.errorPrompt("Oops! Something went wrong while creating the zone.", null, setPrompt, null);
      }
    } catch (err) {
      console.error("Error creating zone:", err);
      // helpers.errorPrompt("Unexpected error occurred. Please try again.", null, setPrompt, null);
    }
  };

  const deleteZone = async () => {
    try {
      
      const request = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      };

      // Send request
      const response = await fetch(user.backEndURL + "/zones/" + props.selectedZone._id, request);
      const jsonResponse = await response.json();

      if (jsonResponse.success) {
        setDisplayDeleteOverlay(false);
        props.reloadZones();
        // helpers.successPrompt("Zone deleted successfully.", null, setPrompt, null);
      } else {
        alert("Oops! Something went wrong while deleting the zone.");
        // helpers.errorPrompt("Oops! Something went wrong while creating the zone.", null, setPrompt, null);
      }
    } catch (err) {
      console.error("Error creating zone:", err);
      // helpers.errorPrompt("Unexpected error occurred. Please try again.", null, setPrompt, null);
    }
  };

  const resetFieldInfo = () => {
    console.log(props.selectedZone)
    setFormData({
      zoneName: props.selectedZone.zone_name,
      zoneId: props.selectedZone.zone_id,
      fieldId: props.selectedZone.field_id,
      fieldName: props.selectedZone.field_name,
      surfaceArea: props.selectedZone.surface_area,
      soilType: props.selectedZone.soil_type,
      fieldType: props.selectedZone.field_type,
    });
    setEditingInfo(false);
  }

  return (
    <div className={helpers.clx(styles.container, props.screenTransitioning ? "" : "")}>
      {renderOverlay()}
      <div className={helpers.clx("contentGrid", styles.zoneContainer)}>
        
        <Module icon={'\uf5cb'} name={"Zone Details"} spanColumn={24} spanRow={1} height={"100%"}>

          {/* 🔹 Zone Details Form */}
          

          <div className={styles.contentContainer}>
            
          {/* 🔹 Applications Section */}
            <div className={styles.applicationsSection}>
              <div className={styles.zoneDetailsHeader}>
                <h3>Applications</h3>
              </div>
              {props.selectedZone && zoneData.applications.length > 0 ? zoneData.applications.map((app, index) => (
                <ApplicationCard app={app} isPlaceholder={false}/>
              )) : 
                <ApplicationCard isPlaceholder={true}/>
              }
            
            </div>

            {/* 🔹 Field Info Section */}
            <div className={styles.fieldInfoSection}>
              <div className={styles.zoneDetailsHeader}>
                <h3>Field Info</h3>
                <div className={styles.iconsContainer}>
                  <div className={helpers.clx(!props.selectedZone && styles.disabled, styles.editIcon)} onClick={() => props.selectedZone && setEditingInfo(true)}>
                    {editingInfo ? "" : "pencil"}
                  </div>
                  <div className={styles.editIcon} onClick={() => props.selectedZone && setDisplayDeleteOverlay(true)}>
                    {editingInfo ? "" : "trash-alt"}   
                  </div>
                </div>
              </div>
              <div className={helpers.clx(styles.contentWrapper, editingInfo ? "" : styles.disabled)}>
                  <TextInput 
                    name="Zone Name*" 
                    value={formData.zoneName} 
                    onChange={(value) => handleChange("zoneName", value)} 
                    labelStyle="top" 
                    placeholder="Enter Zone Name"
                  />
                  <TextInput 
                    name="Zone ID" 
                    value={formData.zoneId} 
                    onChange={(value) => handleChange("zoneId", value)} 
                    labelStyle="top" 
                    placeholder="Enter Zone ID"
                  />
                
                  <TextInput 
                    name="Field Name" 
                    value={formData.fieldName} 
                    onChange={(value) => handleChange("fieldName", value)} 
                    labelStyle="top" 
                    placeholder="Enter Field Name"
                  />
                  <TextInput 
                    name="RPA/RLR Field Number" 
                    value={formData.fieldId} 
                    onChange={(value) => handleChange("fieldId", value)} 
                    labelStyle="top" 
                    placeholder="Enter Field Number"
                  />

                  <TextInput 
                    name="Surface Area (ha)" 
                    value={formData.surfaceArea} 
                    onChange={(value) => handleChange("surfaceArea", value)} 
                    labelStyle="top" 
                    placeholder="Enter Surface Area"
                  />                          
                  

                  <Selector
                    name="Field Type"
                    value={formData.fieldType}
                    onChange={(value) => handleChange("fieldType", value)}
                    labelStyle="top"
                    data={fieldTypeData} // Matches schema enum
                  />

                <div className={helpers.clx(styles.buttonRow, editingInfo ? "" : styles.disabled)}>
                  <Button onPress={() => resetFieldInfo()} name={"Cancel"} color="Coal"/>
                  <Button onPress={() => handleSubmit()} name={"Save Zone"} color="Leaf"/>
                </div>
              </div>
            </div>
          </div>

          

        </Module>
      </div>
    </div>
  );
}

export default ZoneDetails;