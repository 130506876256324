
import styles from './NewPlanScreen.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useCallback, useEffect, useRef} from 'react';
import { useLocation} from "react-router-dom"; //packages

import { UserContext } from '../../UserContext.js';
import { GoogleMap, useJsApiLoader, Polygon, Marker } from '@react-google-maps/api';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import TextInput from "../TextInput.js";
import Selector from "../../components/Selector";
import Checkbox from '../Checkbox.js';
import KeyFigures from '../KeyFigures.js';

import Button from "../Button.js";
import FAQ from "../support/FAQ.js";

import mapStyle from '../../assets/MapStyle.js';
import iconZoneMarker from '../../assets/images/iconZoneMarker.png';
import iconZoneMarkerUnselected from '../../assets/images/iconZoneMarkerUnselected.png';
import iconGrassland  from '../../assets/images/iconGrassland.png';
import iconArable from '../../assets/images/iconArable.png';


import ToggleSwitch from '../ToggleSwitch.js';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
  marginTop: '3px',
  borderRadius: '20px',
  boxShadow: "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12)",
  borderRadius: "1.3rem"
};


//54.713801, -2.749613
const center = {
  lat: 54.713801,
  lng: -2.749613
};

const zonesArray = [
  {
    id: "",
    status: "delivered",
    deliveryDate: "",
    pincharBags: "",
    pelletcharBags: "",
    weight: "",
    volume: ""
  }
]

const yearsAvailable = [
  { name: "2025", value: "2025" },
  { name: "2026", value: "2026" },
  { name: "2027", value: "2027" },
  { name: "2028", value: "2028" },
  { name: "2029", value: "2029" }
]

const fieldTypeData = [
  { name: "Arable", value: "arable" },
  { name: "Grassland", value: "grassland" },
  { name: "Woodland", value: "woodland" },
  { name: "Other", value: "other" }
];

const libraries = ['drawing', 'geometry'];




function NewPlanScreen(props) {
 
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:  process.env.NODE_ENV === 'development' ?  "AIzaSyD2ILH8PS-Y_oS_pgFJ97eNCBhO_2srDN4" : "AIzaSyD2ILH8PS-Y_oS_pgFJ97eNCBhO_2srDN4",//process.env.GOOGLE_MAPS_API_KEY,
    libraries,
  });

const {user, setUser} = useContext(UserContext);
const [map, setMap] = useState(null);
const [allPolygons, setAllPolygons] = useState([]);
const [zones, setZones] = useState([]);
const [selectedZones, setSelectedZones] = useState([]);

const [drawZoneBoundary, setDrawZoneBoundary] = useState(true);// IF TRUE THEN DRAWING POLYGON RATHER THAN SETTING A CENTER POINT
const [zoneGeometry, setZoneGeometry] = useState({});
const markerRef = useRef(null); // Store single marker reference
const polygonRef = useRef(null);
const [existingZones, setExistingZones] = useState([]); // 🔹 Store previous zones from account

const [formData, setFormData] = useState({
  planName: '',
  planYear: '',
  targetedRates: {} // 👈 Map zone IDs to targeted rates
});
const location = useLocation();
const params = location.state || {};

const handleChange = (formField, value) => {
  setFormData((prevData) => ({
    ...prevData,
    [formField]: formField === "surfaceArea" && value !== "" && isNaN(value) 
      ? prevData.surfaceArea // Keep previous valid value if input is invalid
      : value, // Otherwise, store new value
  }));
};

const handleTargetedRateChange = (zoneId, value) => {
  setFormData((prev) => ({
    ...prev,
    targetedRates: {
      ...prev.targetedRates,
      [zoneId]: value
    }
  }));
};

const getDefaultTargetedRates = (zones) => {
  const rates = {
    grassland: 500,
    arable: 1000
  };

  const targeted = {};
  zones.forEach((zone) => {
    const type = zone.field_type?.toLowerCase();
    if (type && rates[type]) {
      targeted[zone._id] = (rates[type] / 1000).toFixed(2); // convert to t/ha
    }
  });
  return targeted;
};


const onLoad = useCallback((mapInstance) => {
  setMap(mapInstance);

  setTimeout(() => {
    const incomingZones = params.zones || [];

    setZones(incomingZones);
    setSelectedZones(["0"]);

    const defaultRates = getDefaultTargetedRates(incomingZones);
    setFormData((prev) => ({
      ...prev,
      targetedRates: defaultRates
    }));

    if (mapInstance && incomingZones.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      incomingZones.forEach(zone => {
        if (zone.center_point?.coordinates?.length === 2) {
          bounds.extend({
            lat: zone.center_point.coordinates[1],
            lng: zone.center_point.coordinates[0]
          });
        }
        if (zone.polygon_shape?.coordinates?.length > 0) {
          zone.polygon_shape.coordinates[0].forEach(coord => {
            bounds.extend({ lat: coord[1], lng: coord[0] });
          });
        }
      });
      mapInstance.fitBounds(bounds);
    }
  }, 100);
}, []);

const onUnmount = useCallback((mapInstance) => {
  setMap(null);
}, []);


const handleSubmit = async () => {
  try {
    
    if(!formData.planName?.length > 0){
      alert("Please add a plan name");
      return;
    }

    if(!formData.planYear?.length > 0){
      alert("Please add a target year");
      return;
    }

    const hasMissingTargetedRates = () => {
      return selectedZones.some((index) => {
        const zone = zones[parseInt(index)];
        const rate = formData.targetedRates[zone._id];
        return rate === undefined || rate === "" || isNaN(rate);
      });
    };

    if (hasMissingTargetedRates()) {
      alert("Please enter a targeted rate for all selected zones.");
      return;
    }


   
    const requestBody = {
      _user: user.details.id,
      plan_name: formData.planName,
      plan_year: formData.planYear,
      targeted_zones: selectedZones.map((index) => {
        const zone = zones[parseInt(index)];
        return {
          _zone: zone._id,
          area: zone.surface_area,
          targeted_rate: parseFloat(formData.targetedRates[zone._id]) || 0
        };
      })
    };
    console.log("REQUEST BODY")
    console.log(requestBody)

    // // 🔹 Construct request body to match backend schema
    // const requestBody = {
    //   _user: user.details.id,
    //   zone_name: formData.zoneName,
    //   field_name: formData.fieldName || formData.zoneName, // Default to zone name if empty
    //   field_id: formData.fieldId,
    //   center_point: finalCenterPoint, // Either manually_set or extrapolated
    //   polygon_shape: finalPolygonShape || null, // Send null if empty
    //   surface_area: formData.surfaceArea || null,
    //   soil_type: formData.soilType || null,
    //   field_type: formData.fieldType || null,
    //   cultivated_in_next_3_years: formData.isCultivating
    // };

    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify(requestBody),
    };

    // Send request
    const response = await fetch(user.backEndURL + "/plans", request);
    const jsonResponse = await response.json();

    if (jsonResponse.success) {
      console.log(jsonResponse.data);
      props.goToScreen("zones"); // Redirect on success
    } else {
      alert("Something went wrong with request");
      // helpers.errorPrompt("Oops! Something went wrong while creating the zone.", null, setPrompt, null);
    }
  } catch (err) {
    alert("Error creating zone:", err);

    console.error("Error creating zone:", err);
    // helpers.errorPrompt("Unexpected error occurred. Please try again.", null, setPrompt, null);
  }
};

const toggleZoneSelection = (index) => {
  const zoneIndex = index.toString();
  setSelectedZones((prev) =>
    prev.includes(zoneIndex)
      ? prev.filter((z) => z !== zoneIndex)
      : [...prev, zoneIndex]
  );
};

const calculateRecommendedBiocharRate = (zone) => {
  const { field_type, surface_area } = zone;

  if (!field_type || !surface_area || isNaN(surface_area)) {
    return null; // Invalid input
  }

  // kg/ha rates
  const rates = {
    grassland: 500,
    arable: 1000
  };

  const rateKgPerHa = rates[field_type.toLowerCase()];
  if (!rateKgPerHa) {
    return null; // Unsupported field type
  }

  const totalKg = surface_area * rateKgPerHa;
  const totalTons = totalKg / 1000;

  return {
    total:  totalTons.toFixed(2), // Round to 2 decimal places
    rate: (rateKgPerHa / 1000).toFixed(2) // Convert to tons/ha and round to 2 decimal places
  }
}

const renderZonesRow = () => {
  let filteredZones = zones.filter(function (zone, index) {
      return selectedZones.includes(index.toString())
    });
  

  const renderZoneName = (name, fieldType) => {
    console.log(name + " *")
    console.log(fieldType )
    switch(fieldType){
      case "grassland":
        return <td className={helpers.clx(styles.zoneRowName, styles.dataBold)}><img src={iconGrassland} className={styles.typeIcon}/>{name}</td>
      case "arable":
        return <td className={helpers.clx(styles.zoneRowName, styles.dataBold)}><img src={iconArable} className={styles.typeIcon}/>{name}</td>
     
      default:
          return <td>N/A</td>
    }

  }

  

  return(
    <>
      {filteredZones.map((zone, index) => {
        return(
          <tr className={styles.tableRow}>
            {renderZoneName(zone.zone_name, zone.field_type)}

            <td className={styles.dataBold}>{zone.surface_area}</td>
            <td className={styles.dataBold}>{zone.biochar_applications.length === 0 ? "0" : "n/a"}</td>
            <td className={styles.dataBold}>{calculateRecommendedBiocharRate(zone).rate}</td>
            <td className={styles.dataInput}><input type={"text"}  value={formData.targetedRates[zone._id] || ""} onChange={(e) => handleTargetedRateChange(zone._id, e.target.value)}  className={styles.dataInputText}/></td>
            <div className={styles.zoneRowRemoveButton} onClick={() => setSelectedZones(selectedZones.filter(z => z !== index.toString()))}>
              <span className={styles.zoneRowRemoveIcon}>&#xf057;</span>
            </div>
          </tr>
        ) 
      })
    }
    </>
  )
}

const renderBreakdown = () => {
  if (!formData || !zones || zones.length === 0) return null;

  const selected = selectedZones
    .map(index => zones[parseInt(index)])
    .filter(Boolean);

  const totalArea = selected.reduce((sum, zone) => {
    return sum + parseFloat(zone.surface_area || 0);
  }, 0);

  const totalTargetKg = selected.reduce((sum, zone) => {
    const rate = parseFloat(formData.targetedRates[zone._id] || 0); // ⬅️ FIXED
    return sum + rate * parseFloat(zone.surface_area || 0) * 1000;
  }, 0);

  const avgTargetRate = totalArea > 0 ? (totalTargetKg / totalArea / 1000).toFixed(2) : "0";
  const totalBags = Math.ceil(totalTargetKg / 215);
  const totalLorries = Math.round((totalBags  )/ 2.4)/10;

 return <KeyFigures data={[
    {label: "Total Area", value: totalArea.toFixed(2), unit: "ha" },
    {label: "Avg. Target Rate", value: avgTargetRate, unit: "t/ha" },
    {label: "Bags Equiv.", value: totalBags, unit: "bags" },
    {label: "Artic Loads", value: totalLorries}
 ]}/>
 

//  return (
//   <div className={styles.breakdownRow}>
//     <div className={styles.breakdownItem}>
//       <div className={styles.breakdownLabel}>Total Area</div>
//       <div className={styles.breakdownValue}>{totalArea.toFixed(2)} <span className={styles.unit}>ha</span></div>
//     </div>
//     <div className={styles.separatorLine}/>

//     <div className={styles.breakdownItem}>
//       <div className={styles.breakdownLabel}>Avg. Target Rate</div>
//       <div className={styles.breakdownValue}>{avgTargetRate} <span className={styles.unit}>t/ha</span></div>
//     </div>
//     <div className={styles.separatorLine}/>

//     <div className={styles.breakdownItem}>
//       <div className={styles.breakdownLabel}>Bags Equiv.</div>
//       <div className={styles.breakdownValue}>{totalBags} <span className={styles.unit}>bags</span></div>
//     </div>
//     <div className={styles.separatorLine}/>

//     <div className={styles.breakdownItem}>
//       <div className={styles.breakdownLabel}>Artic Loads</div>
//       <div className={styles.breakdownValue}>~ {totalLorries}</div>
//     </div>
//   </div>
// );
};

return(
  <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
  <Nav goToScreen={props.goToScreen} currentScreen={"zones"}/>

  <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
    
    <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
      <ScreenHeader name={"New Action Plan"} content={"Add, edit, and delete the zones you own. These are used to map biochar application to your land"}/>
      
      <div className={helpers.clx("contentGrid", styles.contentGrid)}>
        {/* <div className={styles.newZoneButtonContainer}>
          <Button name={"Add New Zone +"} onPress={() => props.goToScreen("/new-zone")} color={"Coal"}/>
        </div> */}
        <Module icon={'\uf648'} name={"Set Plan Targets"} spanColumn={24} height={"100%"}>
          
          { isLoaded ? 
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={8}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                styles: mapStyle,
                disableDefaultUI: true,
                draggable: true,
                zoomControl: true,
                scrollwheel: true,
                disableDoubleClickZoom: true,
                draggableCursor: "crosshair",
                mapTypeId: "hybrid",
              }}
            >
               
              {/* Render existing zones */}
              {params.zones?.map((zone, index) => {
                if (zone.polygon_shape?.coordinates?.length > 0) {
                  // Convert GeoJSON format to Google Maps Polygon format
                  const polygonPaths = zone.polygon_shape.coordinates[0].map(coord => ({
                    lat: coord[1],  // GeoJSON stores [lng, lat], Google Maps uses {lat, lng}
                    lng: coord[0]
                  }));
                  const isSelected = selectedZones.includes(index.toString());

                  return (
                    <>
                      {/* Polygon */}
                      <Polygon
                        key={`polygon-${zone._id}`}
                        paths={polygonPaths}
                        options={{
                          fillColor:  isSelected ? "#B0E000" : "#FFFFFF", // ✅ Your requested color
                          fillOpacity: isSelected ? 0.25 : 0.1, // 🔹 Adjust opacity for unselected
                          strokeColor: isSelected ? "#B0E000" : "#CCCCCC",
                          strokeOpacity: 0.5, 
                          strokeWeight: 2,
                          clickable: true,
                          editable: false,
                          draggable: false,
                        }}
                        onClick={() => toggleZoneSelection(index)} // ✅ ADD THIS
                      />
                      
                      {/* Polygon Label */}
                      <Marker
                        key={`polygon-label-${zone._id}`}
                        position={polygonPaths[0]} // First coordinate as label anchor
                        icon={{
                          url: "", // ✅ Hide default icon
                          scaledSize: new window.google.maps.Size(1, 1),
                          labelOrigin: new window.google.maps.Point(1, 15) // ✅ Adjust label position
                        }}
                        label={{
                          text: zone.zone_name, // ✅ Display the zone name
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                          className: styles.polygonLabel
                        }}
                        onClick={() => toggleZoneSelection(index)} // ✅ ADD THIS
                      />
                    </>
                  );
                } else if (zone.center_point?.coordinates?.length === 2) {
                  return (
                    <>
                      {/* Marker */}
                      <Marker
                        key={`marker-${zone._id}`}
                        position={{
                          lat: zone.center_point.coordinates[1], 
                          lng: zone.center_point.coordinates[0],
                        }}
                        icon={{
                          url:  selectedZones.includes(index.toString()) ? iconZoneMarker : iconZoneMarkerUnselected,
                          scaledSize: new window.google.maps.Size(65, 65),
                          anchor: new window.google.maps.Point(32.5, 65),
                          labelOrigin: new window.google.maps.Point(32.5, 80) // ✅ Moves label BELOW the icon
                        }}
                        label={{
                          text: zone.zone_name,
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                          className: styles.markerLabel
                        }}
                        onClick={() => toggleZoneSelection(index)} // ✅ ADD THIS
                      />
                    </>
                  );
                }
                return null;
              })}
            </GoogleMap>
          : null
          }
          <div className={styles.contentWrapper}>
            <div className={styles.targetedZonesWrapper}>
              <div className={styles.detailsRowHeader}>
                Targeted Zones ( {selectedZones.length}  )
              </div>
              <div className={helpers.clx(styles.detailsRow, styles.zonesRow)}>
                {
                  zones.map((zone, index) => {
                   
                    return(
                       <div className={helpers.clx(styles.zoneTag, selectedZones.includes(index.toString()) ? styles.selectedZone : "")} 
                          onClick={() => {
                          const zoneIndex = index.toString();
                          if (selectedZones.includes(zoneIndex)) {
                            setSelectedZones(selectedZones.filter(z => z !== zoneIndex));
                          } else {
                            setSelectedZones([...selectedZones, zoneIndex]);
                          }
                      }}>
                      {zone.zone_name}
                      </div>
                      
                     
                    )
               
                  }
                )
              }
            
              </div>
            
            </div>
          
           

            <div className={styles.detailsRow}>
              <TextInput 
                name="Plan Name" 
                value={formData.planName} 
                onChange={(value) => handleChange("planName", value)} 
                labelStyle="top" 
                placeholder="Enter Plan Name"
              /> 
              <Selector
                name="Target Year"
                value={formData.planYear ? formData.planYear : "Select Year"}
                onChange={(value) => handleChange("planYear", value)}
                labelStyle="top"
                data={yearsAvailable} // Matches schema enum
              />
                                  
            </div>

            <div className={styles.tableContainer}>
              <table className={styles.orderTable}>
                  <tr className={styles.orderHeaderRow}>
                    <th>Zone</th>
                    <th>Area (ha)</th>
                    <th>Applied (t/ha)</th>
                    <th>Recommended (t/ha)</th>
                    <th>Targeted (t/ha)</th>
                  </tr>
                  {renderZonesRow()}
              </table>
            </div>
            {renderBreakdown()}
            <div className={styles.buttonRow}>
              <Button onPress={() => props.goToScreen("zones")} name={"Cancel"} color="Coal"/>
              <Button onPress={() => handleSubmit()} name={"Save Plan"} color="Leaf"/>
            </div>
          </div>
        </Module>
        {/* <Button onPress={() => setAllPolygons([])}/> */}
        {/* <Module name={"User Details"} spanColumn={10} spanRow={2} height={"100%"}>
          <div/>
        </Module> */}
      </div>
       
    </div>
  </div>
</div>
  )

}

export default NewPlanScreen;
