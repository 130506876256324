import React from 'react';
import styles from './Plans.module.css'; //styles
import Button from '../Button.js'; //styles
import helpers from '../../helpers.js';

import checkIcon from '../../assets/images/check.png'; //styles

const perksTier1 = [
    "Delivered to your farm in 2m³ bags longer text here",
    "Tracked in our carbon platform",

  ];

const perksTier2 = [
    "Delivered to your farm in 2m³ bags",
    "Tracked in our carbon platform",
    "Access to support and advice",
    "Access to support and advice",
];

const perksTier3 = [
    "Delivered to your farm in 2m³ bags",
    "Tracked in our carbon platform",
    "Access to support and advice",
    "Access to support and advice",
    "Access to support and advice",
    "Access to support and advice",
  ];

const renderPerks = (perks) => {
    return perks.map((perk, index) => (
        <div key={index} className={styles.perkRow}>
            <img src={checkIcon} alt="check" className={styles.checkIcon}/>
            <div className={styles.perkText}>
                {perk}
            </div>
        </div>
    ));
};

const Plans = (props) => {
    return(
            <div className={styles.paymentPlansContainer}>
               <div className={styles.paymentPlanContainer}>
                <div className={styles.paymentPlanMainContent}>
                  <div className={styles.paymentPlanNamePrice}>
                    <div className={styles.planName}>
                        INDIVIDUAL PURCHASE
                    </div>
                    <div className={styles.planPrice}>
                        <span className={styles.pricePound}>£</span> 499 <span className={styles.priceDash}> / </span><span className={styles.pricePerTime}> bag </span>
                    </div>
                    </div>
                    <div className={styles.planDescription}>
                      You receive an invoice at the end of each month. Your first invoice will be due in a month.
                    </div>
                    <div className={styles.lineSeparator}/>
                    <div className={styles.perksHeader}>
                      Select this option and get:
                    </div>
                    <div className={styles.perksContainer}>
                      {renderPerks(perksTier1)}
                    </div>
                </div>
                <Button selected={props.paymentPlan === "monthly" ? true : false} nameSelected={"Selected"} onPress={() => props.handleChange("paymentPlan", "monthly")}  customStyle={{width: "80%"}} name={"Select"}/>
                
               </div>
               <div className={styles.recommendedPlanContainer}>
                Recommended
                <div className={helpers.clx(styles.paymentPlanContainer, styles.recommendedPlanContent)}>
                      <div className={styles.paymentPlanNamePrice}>
                          <div className={styles.planName}>
                              1 YEAR
                          </div>
                          <div className={styles.planPrice}>
                              <span className={styles.pricePound}>£</span> 399 <span className={styles.priceDash}> / </span><span className={styles.pricePerTime}> bag </span>
                          </div>
                      </div>
                      <div className={styles.planDescription}>
                        You receive an invoice at the ensd of each month. Your first invoice will be due in a month.
                      </div>
                      <div className={styles.lineSeparator}/>
                      <div className={styles.perksHeader}>
                        Select this option and get:
                      </div>
                      <div className={styles.perksContainer}>
                         {renderPerks(perksTier2)}
                      </div>
                      <Button selected={props.paymentPlan === "quarterly" ? true : false} nameSelected={"Selected"} onPress={() => props.handleChange("paymentPlan", "monthly")}  customStyle={{width: "80%"}} name={"Select"}/>
                </div>
               </div>
              
               <div className={styles.paymentPlanContainer}>
                    <div className={styles.paymentPlanNamePrice}>
                        <div className={styles.planName}>
                            2 YEARS
                        </div>
                        <div className={styles.planPrice}>
                            <span className={styles.pricePound}>£</span> 299 <span className={styles.priceDash}> / </span><span className={styles.pricePerTime}> bag </span>
                        </div>
                    </div>
                    <div className={styles.planDescription}>
                      You receive an invoice at the end of each month. Your first invoice will be due in a month.
                    </div>
                    <div className={styles.lineSeparator}/>
                    <div className={styles.perksHeader}>
                      Select this option and get:
                    </div>
                    {renderPerks(perksTier3)}
                    <Button selected={props.paymentPlan === "yearly" ? true : false} nameSelected={"Selected"} onPress={() => props.handleChange("paymentPlan", "monthly")}  customStyle={{width: "80%"}} name={"Select"}/>
               </div>
            </div>
    )
}

Plans.defaultProps = {
    selected: ""
  };

export default React.memo(Plans);