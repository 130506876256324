// components/ApplicationCard.js
import React from 'react';
import styles from './ApplicationCard.module.css'; // optional, if using module styling

const ApplicationCard = (props) => {
  return (
    <div className={styles.applicationCard}>
      <div className={styles.applicationHeaders}>
          
        <div className={styles.applicationId}>{props.isPlaceholder ? "No data to display ..." : props.app.id}</div>
        <div className={styles.applicationDate}>{props.isPlaceholder ? "_ _ / _ _ /_ _ _ _" : props.app.date}</div>
        <div className={styles.applicationMethod}>{props.isPlaceholder ? "Method" : props.app.method}</div>
      </div>
      <div className={styles.applicationTags}>
        {props.isPlaceholder ? <span className={styles.tag}>No Charcodes</span> : props.app.tags.map((tag, i) => (
          <span key={i} className={styles.tag}>{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default ApplicationCard;