import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import SignatureCanvas from 'react-signature-canvas'


import { UserContext } from "./UserContext.js";


import helpers from "./helpers.js";
import styles from './CharcodeScreen.module.css'; //styles

import Button from "./components/Button.js";
import QRScanner from "./components/QRCodeScanner.js";
import TextInput from "./components/TextInput.js";
import Checkbox from "./components/Checkbox.js";

import Selector from "./components/Selector.js";
import DateSelector from "./components/DateSelector.js";
import Charpoints from "./components/CharpointsBanner.js";





import greenCircles from "./assets/images/greenCirclesCorner.png";
import bbbLogo from "./assets/images/bbbLogo.png";
// import baggingImage from "./assets/images/baggingImage.png";
// import pickupImage from "./assets/images/pickupImage.png";
// import deliveryImage from "./assets/images/deliveryImage.png";
// import applicationImage from "./assets/images/applicationImage.png";

import successImage from "./assets/images/check.png";
import pickedUpImage from "./assets/images/bagPickedUp.png";
import icon404 from "./assets/images/icon404.png";
import dots from "./assets/images/patternDots.png";
import ebc from "./assets/images/EBC.png";
import iconCalendarAlt from "./assets/images/iconCalendarAlt.png";
import iconBalanceScale from "./assets/images/iconBalanceScale.png";
import iconIndustry from "./assets/images/iconIndustry.png";
import iconShoppingBag from "./assets/images/iconShoppingBag.png";
import iconStorage from "./assets/images/iconStorage.png";
import iconTruck from "./assets/images/iconTruck.png";
import iconWater from "./assets/images/iconWater.png";
import iconHeat from "./assets/images/iconHeat.png";
import iconUserCircle from "./assets/images/iconUserCircle.png";
import chromeLocation1 from "./assets/images/chromeLocation1.png";
import chromeLocation2 from "./assets/images/chromeLocation2.png";
import chromeLocation3 from "./assets/images/chromeLocation3.png";








const CharcodeScreen = (props) => {
  document.title = "Charcode Form";

  const [location, setLocation] = useState({ lat: null, long: null });
  const {user, setUser} = useContext(UserContext);
  const {charcodeId } = useParams();
  const [farmer, setFarmer] = useState("12");

  const [mainBag, setMainBag] = useState({});
  const [order, setOrder] = useState({});
  const [bagsFromOrder, setBagsFromOrder] = useState([]);
  const [ebcBatchUrl, setEbcBatchUrl] = useState([]);

  const [retry, setRetry] = useState(false);
  const [charcodesCollapsed, setCharcodesCollapsed] = useState(false);
  // const [requestBody, setRequestBody] = useState({})


  const d = new Date();

  //Bagging
  const [weight, setWeight] = useState(0);
  const [moistureContent, setMoistureContent] = useState("");
  const [internalTemperature, setInternalTemperature] = useState("");

  const [site, setSite] = useState("");

  //Pickup
  const [orders, setOrders] = useState([]);
  const [ordersContent, setOrdersContent] = useState([]); //Used to view previously "picked up" bags already assigned to an order

  // const [orderNumberBags, setOrderNumberBags] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState("")

  //Delivery
  const [applicationDate, setApplicationDate] = useState(d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0'));
  const [endUse, setEndUse] = useState("");
  const [machinery, setMachinery] = useState("");

  const [baggingDate, setBaggingDate] = useState(d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0'));
  const [screen, setScreen] = useState("loading"); //bagging, pickup, delivery, application
  const [successScreen, setSuccessScreen] = useState("success"); // fullyLoaded, bagAdded Determines what the success screen should look like

  const [displayScanner, setDisplayScanner] = useState(false);
  const [displaySignatureOverlay, setDisplaySignatureOverlay] = useState(false);
  const [acceptedTC, setAcceptedTC] = useState(false);
  const [isStorage, setIsStorage] = useState(false);


  const [scanResults, setScanResults] = useState([charcodeId.slice(-10)])
  const [scanResults2, setScanResults2] = useState(["CHA-000001", "CHA-000002", "CHA-000003", "CHA-000004", "CHA-000005", "CHA-000006", "CHA-000007", "CHA-000008", "CHA-000009", "CHA-000010", "CHA-000011", "CHA-000012", "CHA-000013", "CHA-000014", "CHA-000015", "CHA-000016", "CHA-000017", "CHA-000018", "CHA-000019", "CHA-000020"])

  const [totalBagsExpected, setTotalBagsExpected] = useState("?")
  const sigCanvas = useRef(null);



  const clearCanvas = () => {
    sigCanvas.current.clear();
  };

  // useEffect(() => {
  //   console.log(scanResults)
  //   const uniqueSet = new Set(scanResults);
  //   // Convert the Set back to an array
  //   const uniqueArr = [...uniqueSet];
  //   console.log(uniqueArr);
  // },[scanResults])

  const handleSetWeight = (value) => {
    if (!isNaN(value)) {
      setWeight(value);
    }
  };

   const selectOrder = (order) => {
    console.log(order)
    orders.forEach((ord) => {
      if(ord._id === order){
        setTotalBagsExpected(ord.products[0].amount)
      }
    })
    setSelectedOrder(order)   
  }
    
  const checkIfOrderFull = () => {
    if((scanResults.length + ordersContent.length) >= totalBagsExpected){
      return true;
    } else {
      return false;
    }
  }

  const getLocation = async() => {
    if(location.lat !== null){
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("HEREHRE")
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          alert("Location access was denied. Please allow location access and try again.");
          setScreen("error");
          throw new Error("Location denied");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    console.log("Fetching Charcode")
    console.log(props)
    const fetchCharcode = async () => {
      try{
        console.log("HELLO")
        let gettingLocation = await getLocation();
   
        console.log("HELLO123s")
        console.log(gettingLocation)
        console.log(typeof location.lat)
        if(typeof location.lat !== "number"){
          console.log("Exited fetchCharcodes because location wasn't defined")
          return;
        }

        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"   
          }
        };
        console.log(props.backEndURL)        
        console.log(charcodeId)

        let response = await fetch(props.backEndURL + "/charcode/" + charcodeId + "/" ); // , request
        console.log(response);

        // console.log(props.backEndURL + "/charcode/" + charcodeId)
        let jsonResponse = await response.json(); 
        console.log(jsonResponse);
        if (jsonResponse.success) {
        
          console.log("A")
          console.log(jsonResponse.data.bag);
          setMainBag(jsonResponse.data.bag); 
          setEbcBatchUrl("https://www.european-biochar.org/cert/" + jsonResponse.data.bag.batch_id); 

          console.log("A2")
          console.log(jsonResponse.data.bag.status)
       

          if(jsonResponse.data.bag.status === "assigned" && jsonResponse.data.bag._order && Array.isArray(jsonResponse.data.bag._order.products)){
            console.log("A3")

            setTotalBagsExpected(jsonResponse.data.bag._order.products[0].amount)    
          }
          console.log("A4")

          console.log(jsonResponse.data.bag)
          //        
          console.log("b")
          console.log(jsonResponse.data)
          // if(jsonResponse.data.bag.status !== "unassigned"){
          //   setOrderNumberBags(jsonResponse.data.orders[0].products[0].amount);
          // }
          console.log("C")
          let bagScreen;
          switch(jsonResponse.data.bag.status){
            case "unassigned":
              bagScreen = "bagging";
              break;
            case "delivered_to_storage":
            case "bagged" :
              bagScreen = jsonResponse.data.orders === "n/a" ?  "noOrders" : "pickup";
              console.log(jsonResponse.data.orders)
              console.log(jsonResponse.data);
              setOrders(jsonResponse.data.orders);
              setOrdersContent(jsonResponse.data.ordersContent)
              break;
          // case "assigned": REMOVED ASSIGN 
          //   bagScreen = "pickup";
          //   console.log(jsonResponse.data);
          //   setOrders(jsonResponse.data.orders);
          //   break;
            // case "bagged" :
            //     bagScreen = "pickup";
            //     console.log("BAGGEDg")
            //     console.log(jsonResponse.data);
            //     setOrders(jsonResponse.data.orders);
            //     setOrdersContent(jsonResponse.data.ordersContent)
            //     break;
            // // case "assigned": REMOVED ASSIGN 
            // //   bagScreen = "pickup";
            // //   console.log(jsonResponse.data);
            // //   setOrders(jsonResponse.data.orders);
            // //   break;
            case "pickedUp":
              bagScreen = "delivery";
              console.log("ZIZIZIZI")
              console.log(jsonResponse.data)
              setOrders(jsonResponse.data.orders);
              setFarmer(jsonResponse.data.user)
              setScanResults(jsonResponse.data.bagsFromOrder.map((bagFromOrder) => {
                  return {charcode: bagFromOrder.charcode, checked: bagFromOrder.charcode === charcodeId ? true : false};
              }))
       
              setBagsFromOrder(jsonResponse.data.bagsFromOrder);
              console.log("ssssssssssaaaa")
              if(typeof jsonResponse.data.bag.locations.storage_pickup !== "undefined"  && typeof jsonResponse.data.bag.locations.pickup === "undefined" ){
                setTotalBagsExpected(jsonResponse.data.bag.locations.storage_pickup._order_to_storage.products[0].amount)
                setIsStorage(true);

              } else {
                console.log("HRERE")

                setTotalBagsExpected(jsonResponse.data.bag._order.products[0].amount)
              }
              console.log("sssssss")

              break;
            case "delivered":
              setBagsFromOrder(jsonResponse.data.bagsFromOrder);
              bagScreen = "application";
              break; 
            case "applied":
              bagScreen = "alreadyApplied";
              break;
            case "delivered_storage":
              bagScreen = "pickup";
              console.log("BAGGEDg")
              console.log(jsonResponse.data);
              setOrders(jsonResponse.data.orders);
              setOrdersContent(jsonResponse.data.ordersContent)
              break;
          // case "assigned": REMOVED ASSIGN 
          //   bagScreen = "pickup";
          //   console.log(jsonResponse.data);
          //   setOrders(jsonResponse.data.orders);
          //   break;
          }
          console.log(bagScreen)
          setScreen(bagScreen)

          console.log(jsonResponse.data);
          // setOrders(newOrders);
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE  A NINJA");
        } else {
          // alert("Error: " + jsonResponse.message);
          setScreen("404")
          console.log("Fetch Bag Failed...")
        }
      }catch(err){
        // alert("OUPSSS");
        return;
      }
      
      
    }
    fetchCharcode();
  },[props.backEndURL, retry, location])

  

  const removeCharcode = (charcode) => {
    console.log(scanResults);
    setScanResults(prevScanResults => prevScanResults.filter(element => element !== charcode));
  }

  const addCharcode = (charcode) => {
    // alert("CHARCODS")
    // console.log(charcode);
    // console.log(scanResults);
    let copy = scanResults.map((x) => x);
    // console.log(copy);
    if(typeof charcode === "string"){
      copy.push({charcode: charcode, checked: true})
      let result = "";
        if(!scanResults.includes(charcode)){
          result = copy;
          setScanResults(result);
        } else {
          console.log("YAI")
          setScanResults(scanResults)
        }


    }else{
      copy.push(charcode)
      let result = "";
        if(!scanResults.includes(charcode)){
        result = copy;
        setScanResults(result);
        } else {
        console.log("YAI")
        setScanResults(scanResults)
        }
    }
  
    

  }

  const renderOrdersData = (ord) => {
    console.log(ord)
    console.log(orders) // [0]
    let data = ord.map((order) => {
      return order.destination_type === "site" ? 
      ({
        name: <div className={styles.orderId}>
          #{order.order_id.substring(4)} | &nbsp; <img src={iconStorage} className={styles.storageSelector}/> <span style={{"font-weight": "bold", "font-size": "14px"}}>{order._destination_site.name}</span>&nbsp;| &nbsp; <img src={iconShoppingBag} className={styles.shoppingBagSelector}/> &nbsp;{order.products ? order.products[0].amount : "" }</div> ,
        value: order._id
      })
      :
        ({
          name: <div className={styles.orderId}>
            #{order.order_id.substring(4)} | &nbsp;<span style={{"font-weight": "bold", "font-size": "14px"}}>{order._user.business_name}</span>&nbsp;|&nbsp; <img src={iconShoppingBag} className={styles.shoppingBagSelector}/> &nbsp;{order.products ? order.products[0].amount : "" }</div> ,
          value: order._id
        })
    })
    return(data);
  }

  

  const updateScannedCharcodes = (charcode, isBeingAdded) => {
    if(isBeingAdded){
      console.log("a")
      console.log(charcode);
      // console.log(scanResults);
      setScanResults(prevScanResults => [...prevScanResults, charcode])
    } else {
      const updatedItems = scanResults.filter((_, index) => index !== scanResults.indexOf(charcode));
      setScanResults(updatedItems)
    }
  }

  const collapseCharcodes = () => {
    setCharcodesCollapsed(!charcodesCollapsed)
  }

  const updateBagsStatus = async () => {


    //LOGIC HERE 

    let bags = [];
    let requestBody;

    ///BAGGING
    switch(screen){
      case "bagging":
        console.log("AAAAAAA1")
        for (const scanResult of scanResults ) {
          console.log(scanResult)
          let bag = {
            charcode: scanResult,
            baggingDate: baggingDate,
            weight: weight,
            site: site,
            moistureContent: moistureContent,
            internalTemperature: internalTemperature
          };
          console.log(bag)
          bags.push(bag);
        }
        console.log("AAAAAAA2")

        requestBody = {
          bagsToUpdate: bags,
          newStatus: "bagged",
          location: location
        };
        console.log("CCCCbaggingCCC");
        console.log(requestBody);
        break; 


//ASSIGNMENT
      case "assign":
        scanResults.forEach((charcode) => {
          let bag = {
            charcode: typeof charcode === "string" ? charcode : charcode.charcode,
            site: site
          };
          bags.push(bag);
        })
        requestBody = {
          bagsToUpdate: bags,
          selectedOrder: selectedOrder,
          newStatus: "assigned",
          location: location
        }
        console.log("DDDassigningDDD");
        console.log(requestBody);
        break; 


        //PICKUP
      case "pickup":
        console.log(selectedOrder)
        let filteredOrders = orders.filter(order => order._id === selectedOrder);
        let deliverToStorage = false;
        if (filteredOrders[0].destination_type === "site"){
          deliverToStorage = true;
        } 
        scanResults.forEach((charcode) => {
          let bag = {
            charcode: typeof charcode === "string" ? charcode : charcode.charcode
          };
          bags.push(bag);
        })
        
        
        requestBody = {
          bagsToUpdate: bags,
          newStatus: "pickedUp",
          selectedOrder: selectedOrder,
          deliverToStorage: deliverToStorage,
          // selectedDelivery: selectedDelivery,
          location: location
        }
        setSuccessScreen(checkIfOrderFull() ? "fullyLoaded" : "bagAdded")
        break; 

      //DELIVERY

      case "delivery":
        scanResults.forEach((charcode) => {
          let bag = {
            charcode: typeof charcode === "string" ? charcode : charcode.charcode
          };
          bags.push(bag);
        })
        if(sigCanvas.current.isEmpty()) {
          alert("Please provide a signature first.");
          return;
        }
        
        const signatureDataURL = sigCanvas.current.toDataURL();

        requestBody = {
          bagsToUpdate: bags,
          newStatus: "delivered",
          selectedOrder: selectedOrder,
          location: location,
          signatureUrl: signatureDataURL,
          deliverToStorage: isStorage
        };
   


        break;
       //DELIVERY

      case "application":
       scanResults.forEach((charcode) => {
        let bag = {
          charcode: typeof charcode === "string" ? charcode : charcode.charcode,
          appliedTo: endUse,
          applicationDate: applicationDate,
        };
        bags.push(bag);
      })
      requestBody = {
        bagsToUpdate: bags,
        newStatus: "applied",
        selectedOrder: selectedOrder,
        location: location
      };
      break;
      case "application":
       return;
      
    }

    console.log(requestBody);
    const request =  {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    };
    console.log(request)
    console.log(user.backEndURL);
    console.log("MADE A REQUEST")
    let response = await fetch(user.backEndURL + "/charcodes", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log("SUCCEEDED A REQUEST")

      console.log(jsonResponse);
      setScreen("success");
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      alert("Error: " + jsonResponse.message);
      console.log(jsonResponse)
      console.log("FAILLED A REQUEST")
      console.log("Fetch Subscription Failed...")
    }

    console.log("HEREEEEE")
  }








  const resetSignature = () => {

  }








  
  
  const renderContent = () => {
    switch(screen){
      case "loading": return (
        <div className={styles.coolFont}> 
          Charcode Loading...
        </div>
      ); 
      case "404": return (
        <> 
        <img src={icon404} className={styles.mainIllustration}/>
        <div className={styles.header}>
          Bag not found..
        </div>
        <div className={styles.description}>
            Server was unable to find Charcode #{charcodeId}. Check that the Charcode ID is correct.
        </div>
        <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
            <div>
              <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
            </div>
            <div className={styles.collapseIcon} >
              {charcodesCollapsed ? "chevron-down" : "chevron-up"}
            </div>
          </div>
          <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
          {scanResults.map((scan) => {
              return(
                <div className={styles.headerCharcode}>
                  {scan}
                </div>
              )            
            })}
        </div>
      </>
      ); 
      case "noOrders": return (
        <> 
        <img src={icon404} className={styles.mainIllustration}/>
        <div className={styles.header}>
          No orders scheduled for today..
        </div>
        <div className={styles.description}>
            Server was unable to find any orders. Check with the BBB team for more details.
        </div>
        <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
            <div>
              <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
            </div>
            <div className={styles.collapseIcon} >
              {charcodesCollapsed ? "chevron-down" : "chevron-up"}
            </div>
          </div>
          <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
          {scanResults.map((scan) => {
              return(
                <div className={styles.headerCharcode}>
                  {scan}
                </div>
              )            
            })}
        </div>
      </>
      ); 
      
      case "alreadyApplied": 
        const d = new Date(mainBag.application_date);
        const day = String(d.getDate()).padStart(2, '0'); // Get the day and add leading zero if needed
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
        const year = d.getFullYear(); // Get the full year
        const formattedDate = `${day}/${month}/${year}`;
        return (
          <> 
            <img src={successImage} className={styles.mainIllustration}/>
            <div className={styles.header}>
                Bag Already Applied!
            </div>
            <div className={styles.alreadyAppliedText}>
            <span className={styles.alreadyAppliedTextBold}>{mainBag.charcode}</span>&nbsp;| Applied to: <span className={styles.alreadyAppliedTextBold}>&nbsp;{mainBag.applied_to}&nbsp;</span>  | Applied on: <span className={styles.alreadyAppliedTextBold}>&nbsp;{formattedDate}&nbsp;</span>
            </div>
            <div className={styles.description}>
              For more info regarding the biochar you've already applied, login to the desktop BBB Dashboard.
            </div>
          </>
        );
      case "error": {
        let device;
          const userAgent = navigator.userAgent.toLowerCase();
          if (/android/i.test(userAgent)) {
           device = 'android';
          } else if (/iphone|ipad|ipod/i.test(userAgent)) {
           device = 'ios';
          } else {
            device = 'other';
          }
        
         if (device === 'android') {
          return (
            <>
              <div className={styles.header}>
                Locations Services required
              </div>
              <div className={helpers.clx(styles.description, styles.locationHeadedr)}>
                How to enable location services on Chrome
              </div>
              <ol className={styles.chromeLocationContainer}>
                <img src={chromeLocation1} className={styles.chromeLocation}/>
                <li className={styles.chromeText}>Open the <strong>Settings</strong> on Chrome's URL bar.</li>
                <img src={chromeLocation2} className={styles.chromeLocation}/>

                <li className={styles.chromeText}>Tap on <strong>Permissions</strong>.</li>
                <img src={chromeLocation3} className={styles.chromeLocation}/>
                <li className={styles.chromeText}>Toggle <strong>Location</strong> ON and refresh the page.</li>
              </ol>
              <div className={helpers.clx(styles.description, styles.locationHeadedr)}>
                How to enable location services on Android
              </div>
              <ol className={styles.androidLocationContainer}>
                <li>Open the <strong>Settings</strong> app on your Android device.</li>
                <li>Scroll down and tap on <strong>Location</strong>.</li>
                <li>Toggle <strong>Location</strong> ON.</li>
                <li>Tap on <strong>App Permissions</strong> or <strong>Location Access for Apps</strong>.</li>
                <li>
                  Find your browser (e.g., <strong>Chrome</strong>) or the app requesting location services and ensure <strong>Allow</strong> or <strong>Allow while using the app</strong> is selected.
                </li>
              </ol>
              <Button name={"Retry"} onPress={() => setRetry(!retry)}/>
            </>
          );
        } else if (device === 'ios') {
          return (
            <>
              <div className={styles.header}>
                Locations Services required
              </div>
              <div className={styles.description}>
               How to Enable Location Services on iOS (iPhone/iPad)
              </div>
              <ol>
                <li>Open the <strong>Settings</strong> app on your iPhone or iPad.</li>
                <li>Scroll down and tap on <strong>Privacy</strong>.</li>
                <li>Tap on <strong>Location Services</strong> at the top.</li>
                <li>Ensure <strong>Location Services</strong> is toggled ON.</li>
                <li>Scroll down to find your browser (e.g., <strong>Safari</strong>, <strong>Chrome</strong>).</li>
                <li>
                  Tap on the browser name and select <strong>While Using the App</strong> or <strong>Always</strong> to grant location access.
                </li>
              </ol>
              <Button name={"Retry"} onPress={() => setRetry(!retry)}/>
            </>
          );
        } else {
          return <div className={styles.header}>Instructions are not available for your device.</div>;
        }
    
    }
      case "bagging": return (
        <>  <div className={helpers.clx(styles.charcodesContainer, styles.charcodesContainerBagging)}>
              {scanResults.map((scan) => {
                return(
                  <div className={styles.headerCharcode}>
                    {scan}
                  </div>
                )            
              })}
          
          </div>
            <div className={styles.header}>
              Add New Biochar Bag
            </div>
            <div className={styles.description}>
              Select bagging site, bag weight, and bagging date. You may process multiple bags in one go by clicking the “+ Add/Remove Bags” button
            </div>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionHeaderMain}>
                <img src={iconIndustry} className={styles.fontAwesomeSubstitute}/>  <span className={styles.sectionHeaderText}>Site</span>
              </div>
            </div>
            <div className={styles.inputRow}>
              <Selector name={"Site Name"} labelStyle={"none"} value={site} onChange={setSite} data={[{name: "Ahlstrom", value: "ARA"}, {name: "Jenkinson", value: "JNR"}, {name: "Test Site", value: "Test"}]}/>
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
             
              <div className={styles.moistureContainer} >
                <div className={helpers.clx(styles.sectionHeader, styles.moistureInput)}>
                  <TextInput disabled={false} name={"Moist. Content (%)"} labelStyle={"top"} iconCustom={iconWater} value={moistureContent} onChange={setMoistureContent}/>
                </div>
                <div className={helpers.clx(styles.sectionHeader, styles.moistureInput)}>
                  <TextInput disabled={false} name={"Bag Temp. (°C)"} labelStyle={"top"} iconCustom={iconHeat} value={internalTemperature} onChange={setInternalTemperature}/>
                </div>
              </div>
            </div>
           

          
            <div className={styles.inputRow}>
               <div className={helpers.clx(styles.sectionHeader, styles.weightContainer)}>
                 <TextInput disabled={false} name={"Weight (kg)"} labelStyle={"top"} iconCustom={iconBalanceScale} value={weight} onChange={handleSetWeight}/>
               </div>
               <div className={helpers.clx(styles.sectionHeader)}>
                 <DateSelector name={"Bagging Date"} labelStyle={"top"} iconCustom={iconCalendarAlt} value={baggingDate} onChange={setBaggingDate}/>
               </div>
            </div>
            <div className={styles.ghostSpace}/>
            <Button disabled={site === "" ? true: false} name="Confirm Bagging" onPress={updateBagsStatus} color={"Grass"}/>
            <div className={styles.line}/>
            
        </>
      )
      case "assign": return (
       
        <> 
            <div className={helpers.clx(styles.header, styles.smallerHeader)}>
             Assign Bags
            </div>
            <div className={styles.description}>
              Assign bags to an order. You may process multiple bags in one go by clicking the “+ Add/Remove Bags” button
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <img src={iconShoppingBag} className={styles.fontAwesomeSubstitute}/>  Bags {"(" + scanResults.length + " / " + totalBagsExpected + ")"}
              </div>
              <div className={styles.collapseIcon} onClick={() => collapseCharcodes()}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
              {scanResults.map((scan) => {
                  return(
                    <div className={styles.headerCharcode}>
                      {scan}
                    </div>
                  )            
                })}
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add/Remove Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)} totalBagsExpected={totalBagsExpected}/>
              : null
            }
            <div className={helpers.clx(styles.inputRow, styles.orderSelector)}>
             <Selector name={"Order ID"} labelStyle={"top"} iconName={"truck-moving"} value={selectedOrder} onChange={selectOrder} data={renderOrdersData(orders.filter(order => order.status === "upcoming"))} placeholder={"Select Order"} />    
            </div>
            <Button color={"Grass"} disabled={(selectedOrder === "" && scanResults.length !== totalBagsExpected )? true : false} name="Assign To Order" onPress={updateBagsStatus}/>
            <div className={styles.line}/>
            {ebcBatchUrl && ebcBatchUrl !== "" ?
               <div className={styles.productInformation} onClick={() => window.open(ebcBatchUrl === ("https://www.european-biochar.org/cert/" + mainBag.batch_id) ? "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" : ebcBatchUrl , '_blank').focus()}>
                <img src={ebc} className={styles.ebcLogo}/>
                EBC Information
                <span className={styles.productFontAwesome}>
                  chevron-right
                </span>
              </div>
            :
                  ""
            }
         
        </>
      )
      case "pickup":return (
        <> 
            <div className={styles.header}>
             Confirm Pickup
            </div>
            <div className={styles.description}>
              Confirm inventory being picked up. You may process multiple bags in one go by clicking the “+ Add/Remove Bags” button
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
              <img src={iconShoppingBag} className={styles.fontAwesomeSubstitute}/>  Bags {"(" + (scanResults.length + ordersContent.filter((item) => item.order_id === selectedOrder).length) + " / " + totalBagsExpected + ")"}
              </div>
              <div className={styles.collapseIcon} onClick={() => collapseCharcodes()}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
              {()=>{
                console.log(ordersContent); //GPT
                console.log(selectedOrder)
              }
              }
              {ordersContent
              .filter((item) => item.order_id === selectedOrder)
              .map((item) => { 
                console.log(item)
                    return(
                      <div className={helpers.clx(styles.headerCharcode, styles.headerCharcodeAlreadyComplete)}>
                        {item.charcode}
                      </div>
                    )            
                  })}
              {scanResults.map((scan) => {
                  return(
                    <div className={styles.headerCharcode}>
                      {typeof scan === "string" ? scan : scan.charcode}
                    </div>
                  )            
                })}
              <div className={helpers.clx(styles.headerCharcode, checkIfOrderFull() ? styles.disabled : "")} onClick={() => setDisplayScanner(true)}>
                + Add/Remove Bags
              </div>
            
            </div>
            <div className={helpers.clx(styles.inputRow, styles.orderSelector)}>
                <Selector name={"Order ID"} labelStyle={"top"} iconName={"truck-moving"} value={selectedOrder} onChange={selectOrder} data={renderOrdersData(orders.filter(order => order.status === "upcoming"))} placeholder={"Select Order"} />    
              </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)} totalBagsExpected={totalBagsExpected}/>
              : null
            }
            {/* <div className={styles.inputRow}>
              <Selector name={"Delivery ID"} labelStyle={"top"} value={selectedDelivery} onChange={setSelectedDelivery} data={deliveries.map((delivery) => ({name: delivery.delivery_id, value: delivery._id}))} placeholder={"Select Delivery"} iconName={"truck-moving"}/>
            </div> */}
            <Button color={"Grass"} disabled={false} name="Confirm Pickup" onPress={updateBagsStatus}/>
            <div className={styles.line}/>

            {ebcBatchUrl && ebcBatchUrl !== "https://www.european-biochar.org/cert/" + "" ?
              <div className={styles.productInformation} onClick={() => {
                window.open(ebcBatchUrl === "https://www.european-biochar.org/cert/ba-gb-ca11-01" 
                ?  "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" 
                : ebcBatchUrl.includes("ba-gb-ca11-02") ? 
                "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" 
                :  ebcBatchUrl.includes("ba-gb-m26-02") ? 
                "https://www.european-biochar.org/cert/341e-2531-z3d1-5rgq" 
                : ebcBatchUrl  , '_blank').focus()}
              }
             >
                <img src={ebc} className={styles.ebcLogo}/>
                EBC Information
                <span className={styles.productFontAwesome}>
                  chevron-right
                </span>
              </div>
            :
                  ""
            }
          
        </>
      )
      case "delivery": 

      console.log("DELIVERY")
       console.log(props);
       
       return (
        <> 

          {/* SIGNATURE OVERLAY HERE */}
          <div className={helpers.clx(styles.signatureOverlay, displaySignatureOverlay ? "": styles.signatureOverlayHidden)}>
            
            <div className={helpers.clx(styles.header, styles.smallerHeader)}>
              Finalise Biochar Delivery
            </div>
            <div className={styles.description}>
              Please sign and accept BBB's terms and conditions to finalise your biochar order.
            </div>
            <div className={helpers.clx(styles.sectionHeader, styles.sectionSignature)}>
              <div>
                <span className={styles.headerIcon}>signature</span> Customer Signature
              </div>
            </div>
            <div className={styles.signatureContainer}>
              <SignatureCanvas penColor='black' canvasProps={{/*width: 500, height: 200,*/ className: styles.signature}} ref={sigCanvas}/>
              <div className={styles.resetSignature} onClick={() => clearCanvas()}>trash-restore-alt</div>
            </div>
            <Checkbox 
              thinnerText={false}
              text={"I have checked the contents of my order and confirm they are correct."} 
              checked={acceptedTC} 
              onPress={() => setAcceptedTC(!acceptedTC)}
              />
              <div className={styles.backButtonContainer}>
                <Button disabled={scanResults.length === 0 ? true : false} name="Back" onPress={() => setDisplaySignatureOverlay(false)} color={"Coal"}/>
              </div>
            <Button disabled={scanResults.length === 0 || !acceptedTC ? true : false} name="Complete Order" onPress={updateBagsStatus} color={"Leaf"}/>

          </div>
          <div className={helpers.clx(styles.header, styles.smallerHeader)}>
             Confirm Biochar Delivery
           </div>
            <div className={styles.description}>
              Please check and confirm delivery inventory. You may check each item by scanning QRs, or by clicking on each bag in the list below.
            </div>
            <div className={helpers.clx(styles.sectionHeader, styles.sectionFarmer)}>
              <div>
                  <img src={iconUserCircle} className={styles.fontAwesomeSubstitute}/>  {farmer}
              </div>
              {/* <div className={styles.collapseIcon}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div> */}
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                  <img src={iconShoppingBag} className={styles.fontAwesomeSubstitute}/> Bags {"(" + scanResults.filter(scan => scan.checked).length + " / " + totalBagsExpected + ")"}
              </div>
              <div className={styles.collapseIcon}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "", displaySignatureOverlay ? styles.hideBags : "")}>
              {scanResults.map((scan) => {
                  return(
                    // <div className={helpers.clx(styles.headerCharcode, charcodesCollapsed ? styles.headerCharcodeCollapsed : "")}>
                    //   {scan}
                    // </div>
                    <div className={helpers.clx(styles.headerCharcodeCheckbox)} onClick={() => setScanResults(prevResults => prevResults.map(item =>
                      item.charcode === scan.charcode 
                        ? { ...item, checked: !item.checked } 
                        : item
                    ))}>
                      {/* {scan} */}
                      <Checkbox 
                        customStyle={{"font-size": "12px", "margin-right": "10px", "padding-left": "7px", "letter-spacing": "-0.05rem" }}
                        thinnerText={false}
                        text={"#" + scan.charcode.substring(4,10)} 
                        checked={scan.checked} 
                        onPress={() => console.log("checkbox press")}
                       />
                    </div>
                  )            
                })}
              <div className={helpers.clx(styles.headerCharcode, styles.headerCharcodeAdd)} onClick={() => setDisplayScanner(true)}>
                + Add/Remove Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => {console.log(charcode);updateScannedCharcodes({charcode: charcode, checked: true}, isBeingAdded)}} closeScanner={() => setDisplayScanner(false)} totalBagsExpected={totalBagsExpected}/>
              : null
            }
              {/* <div className={styles.inputRow}>
                <Selector name={"Order ID"} labelStyle={"top"} iconName={"truck-moving"} value={selectedOrder} onChange={setSelectedOrder} data={orders.map((order) => ({name: order.order_id + " (" + order._user.business_name + ")", value: order._id}))} placeholder={"Select Order"}/>
              </div> */}
              {
                isStorage ?
                  null
                  :
                   <Charpoints numberOfBags={scanResults.filter(scan => scan.checked).length}/>

              }

              {
                false ?
                <>
                  <div className={styles.sectionHeader}>
                    <div>
                      <span className={styles.headerIcon}>signature</span> Customer Signature
                    </div>
                  </div>
                  <div className={styles.signatureContainer}>
                    <SignatureCanvas penColor='black' canvasProps={{/*width: 500, height: 200,*/ className: styles.signature}} ref={sigCanvas}/>
                    <div className={styles.resetSignature} onClick={() => clearCanvas()}>trash-restore-alt</div>
                  </div>
                </>
                :
                null
                
              }
            <Button disabled={scanResults.length === 0 || scanResults.filter(scan => scan.checked).length !== totalBagsExpected ? true : false} name="Confirm Inventory" onPress={() => setDisplaySignatureOverlay(true)} color={"Coal"}/>

            <div className={styles.line}/>
            {ebcBatchUrl && ebcBatchUrl !== "https://www.european-biochar.org/cert/" + "" ?
              <div className={styles.productInformation} onClick={() => {
                window.open(ebcBatchUrl === "https://www.european-biochar.org/cert/ba-gb-ca11-01" 
                ?  "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" 
                : ebcBatchUrl.includes("ba-gb-ca11-02") ? 
                "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" 
                :  ebcBatchUrl.includes("ba-gb-m26-02") ? 
                "https://www.european-biochar.org/cert/341e-2531-z3d1-5rgq" 
                : ebcBatchUrl  , '_blank').focus()}
              }
             >
                <img src={ebc} className={styles.ebcLogo}/>
                EBC Information
                <span className={styles.productFontAwesome}>
                  chevron-right
                </span>
              </div>
            :
                  ""
            }
            {/* {ebcBatchUrl && ebcBatchUrl !== "" ?
             <div className={styles.productInformation} onClick={() => window.open(ebcBatchUrl === ("https://www.european-biochar.org/cert/" +  mainBag.batch_id) ? "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" : ebcBatchUrl, '_blank').focus()}>
              <img src={ebc} className={styles.ebcLogo}/>
              EBC Information
              <span className={styles.productFontAwesome}>
                chevron-right
              </span>
            </div>
            :
                  ""
            } */}
           
        </>
      )
      case "application": return (
        <> 
          
            <div className={styles.header}>
              Biochar Application
            </div>
            <div className={styles.description}>
              Enter details regarding how you’re applying biochar to your fields, and earn 10 pounds back for each bag you scan. 
            </div>
            <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <img src={iconShoppingBag} className={styles.fontAwesomeSubstitute}/> Bags ({scanResults.length})
              </div>
              <div className={styles.collapseIcon} onClick={() => collapseCharcodes()}>
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
              {scanResults.map((scan) => {
                // console.log(scan)
                scan = typeof scan === "string" ? scan : scan.charcode;
                  return(
                    <div className={styles.headerCharcode}>
                      {scan}
                    </div>
                  )            
                })}
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add/Remove Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)} />
              : null
            }
            {/* <div className={styles.inputRow}>
              <Selector name={"Delivery ID"} labelStyle={"top"} value={selectedDelivery} onChange={setSelectedDelivery} data={deliveries.map((delivery) => ({name: delivery.delivery_id, value: delivery._id}))} placeholder={"Select Delivery"} iconName={"truck-moving"}/>
            </div> */}
            <Charpoints numberOfBags={scanResults.length}/>
            <div className={styles.inputRow}>
              <div className={styles.applicationDateContainer}>
                <DateSelector name={"Application date"} labelStyle={"top"} value={applicationDate} onChange={setApplicationDate}/>
              </div>
              <Selector name={"Applied to"} placeholder={"Select"} labelStyle={"top"} value={endUse} onChange={setEndUse} data={[{name: "Bedding", value: "bedding"}, {name: "Slurry", value: "slurry"}, {name: "FYM", value: "fym"}, {name: "Direct to land", value: "directToLand"}]}/>

            </div>
            {/* <div className={styles.inputRow}>
              <Selector name={"Field Applied To"} placeholder={"Select Application"} labelStyle={"top"} value={endUse} onChange={setEndUse} data={[{name: "Bedding", value: "bedding"}, {name: "Slurry", value: "slurry"}, {name: "FYM", value: "fym"}, {name: "Direct to land", value: "directToLand"}]}/>
            </div> */}
            <div className={styles.buttonWrapper}>
              <Button color={"Grass"} disabled={false} name="Confirm Application" onPress={updateBagsStatus}/>
            </div>
            <div className={styles.line}/>
            {
                ebcBatchUrl && ebcBatchUrl !== "" ?
                  <div className={styles.productInformation} onClick={() => {
                    window.open(ebcBatchUrl === "https://www.european-biochar.org/cert/ba-gb-ca11-01" 
                    ?  "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" 
                    : ebcBatchUrl.includes("ba-gb-ca11-02") ? 
                    "https://biochar.easy-cert.cloud/analytical-result/G9R5P8Y52E/VRY5EQ85G4" 
                    :  ebcBatchUrl.includes("ba-gb-m26-02") ? 
                    "https://www.european-biochar.org/cert/341e-2531-z3d1-5rgq" 
                    : ebcBatchUrl  , '_blank').focus()}
                  }>
                  <img src={ebc} className={styles.ebcLogo}/>
                  EBC Information
                  <span className={styles.productFontAwesome}>
                    chevron-right
                  </span>
                </div>
            :
                 ""
            }
    
            {/* <div className={styles.charcodesContainer}>
              <div className={styles.headerCharcode}>
                {mainBag.charcode}
              </div>
              <div className={styles.headerCharcode} onClick={() => setDisplayScanner(true)}>
                + Add/Remove Bags
              </div>
            </div>
            { displayScanner ? 
              <QRScanner scanResults={[...scanResults]} removeCharcode={removeCharcode} addCharcode={addCharcode} updateScannedCharcodes={(charcode, isBeingAdded) => updateScannedCharcodes(charcode, isBeingAdded)} closeScanner={() => setDisplayScanner(false)}/>
              : null
            }

            <div className={styles.content}>
              <div className={styles.inputRow}>
                <TextInput name={"Number of bags used"} labelStyle={"top"} value={bagsNumber} onChange={setBagsNumber}/>
                <DateSelector name={"Application date"} labelStyle={"top"} value={applicationDate} onChange={setApplicationDate}/>
              </div>
              <div className={styles.inputRow}>
                <Selector name={"Biochar applied to"} labelStyle={"top"} value={endUse} onChange={setEndUse} data={[{name: "Bedding", value: "bedding"}, {name: "Slurry", value: "slurry"}, {name: "FYM", value: "fym"}, {name: "Direct to land", value: "directToLand"}]}/>
                <TextInput name={"Machinery used"} labelStyle={"top"} value={machinery} onChange={setMachinery}/>
              </div>
              <div className={styles.inputRow}>
                Please press on button to confirm your biochar delivery.
              </div>
            </div>
            <Button name="Confirm Delivery" onPress={updateBagsStatus}/> */}
        </>
      )
      case "success": return (
        <> 
          {successScreen === "bagAdded" ? 
            <img src={pickedUpImage} className={styles.mainIllustration}/> 
              :           
            <img src={successImage} className={styles.mainIllustration}/>
          }
          <div className={styles.header}>
            {successScreen === "success" ? 
              "Bag(s) Successfully Updated!"
                :           
              ""
            }
            {successScreen === "bagAdded" ? 
              "Bag(s) Added!"
                :           
              ""
            }
            {successScreen === "fullyLoaded" ? 
              "Full Shipment Loaded!"
                :           
              ""
            }
            
          </div>
          <div className={styles.description}>
              The following bags were successfully updated:
          </div>
          <div className={styles.sectionHeader} onClick={() => collapseCharcodes()}>
              <div>
                <span className={styles.headerIcon}>shopping-bag</span> Bags ({scanResults.length})
              </div>
              <div className={styles.collapseIcon} >
                {charcodesCollapsed ? "chevron-down" : "chevron-up"}
              </div>
            </div>
            <div className={helpers.clx(styles.charcodesContainer, charcodesCollapsed ? styles.charcodesCollapsed : "")}>
            {successScreen === "fullyLoaded" ? 
              ordersContent
                .filter((item) => item.order_id === selectedOrder)
                .map((item) => { 
                  console.log(item)
                      return(
                        <div className={helpers.clx(styles.headerCharcode, styles.headerCharcodeAlreadyComplete)}>
                          {item.charcode}
                        </div>
                      )            
                    })
              : 
              ""
            }
            {scanResults.map((scan) => {
                return(
                  <div className={helpers.clx(styles.headerCharcode, successScreen === "fullyLoaded" ? styles.headerCharcodeAlreadyComplete: "")}>
                    {typeof scan === "string" ? scan : scan.charcode}
                  </div>
                )            
              })}
          </div>
        </>
      )
    }
  }

  return (
    <div className={styles.fullContainer}>
      <div className={styles.patternBg}/>
      <img src={dots} className={styles.pattern1}/>
      <img src={dots} className={styles.pattern2}/>
     
      <div className={styles.blackContainer}>
        <div className={styles.logoContainer}>
          <img src={bbbLogo} className={styles.logo}/>
        </div>
        <div className={styles.container}>

          <img src={greenCircles} className={styles.circle1}/>
          <img src={greenCircles} className={styles.circle2}/>

          {renderContent()}
        </div>
      </div>
      <div className={styles.websiteLink} onClick={() => window.open("https://blackbullbiochar.com", '_blank').focus()}>
        <span className={styles.fontAwesome}>globe</span> BLACKBULLBIOCHAR.COM
      </div>
   
    </div>
    
  );
}




export default CharcodeScreen;