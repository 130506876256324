import styles from './PlanDetails.module.css';
import helpers from '../../helpers.js';
import Module from '../Module.js';
import Gauge from '../subscription/Gauge.js';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../UserContext.js';
import Button from "../Button.js";
import Overlay from "../Overlay.js";
// import PlanDetailsSummary from "./PlanDetailsSummary.js";
import ApplicationCard from "./ApplicationCard.js";
import iconGrassland from "../../assets/images/iconGrassland.png";
import iconArable from '../../assets/images/iconArable.png';


import TextInput from "../TextInput.js";
import Selector from "../../components/Selector";
import Checkbox from '../Checkbox.js';
import KeyFigures from '../KeyFigures.js';
import { render } from '@testing-library/react';

const PlanDetails = (props) => {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({});
  const [displayDeleteOverlay, setDisplayDeleteOverlay] = useState(false);

  // const [formData, setFormData] = useState({});
  if (!props.selectedPlan) return null;

  /*
  const handleChange = (formField, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [formField]: formField === "surfaceArea" && value !== "" && isNaN(value) 
        ? prevData.surfaceArea // Keep previous valid value if input is invalid
        : value, // Otherwise, store new value
    }));
  };
  */


  /*const renderOverlay = () => {
    if(!displayDeleteOverlay){
      return;
    }
    return(
      <Overlay>
        <div className={styles.deleteZoneContainer}>
           <h2>Delete Zone?</h2>
          <p>Are you sure you want to delete this zone? Type "<b>{props.selectedZone.zone_name}</b>" to delete zone</p>
          <div className={styles.deleteInput}>
            <TextInput 
              name="Zone ID" 
              value={formData.overlayInput} 
              onChange={(value) => handleChange("overlayInput", value)} 
              labelStyle="top" 
              placeholder="Enter Zone ID"
            />
            </div>
          <Button name={"Delete Zone"} disabled={props.selectedZone.zone_name === formData.overlayInput ? false : true} color={"Error"} onPress={() => deleteZone()}/>
          <Button name={"Cancel"}  color={"Coal"} onPress={() => setDisplayDeleteOverlay(false)}/>
        </div>
      </Overlay>
    ) 
  }*/
  // Calculate the color intensity for application rates
  const getApplicationRateColor = (appliedRate, targetRate) => {
    const ratio = appliedRate / targetRate;
    if (ratio >= 1) return "#34B61F"; // Dark green if applied rate meets or exceeds target
    return `rgba(52, 182, 31, ${Math.max(ratio, 0.2)})`; // Adjust opacity based on ratio
  };

  const renderOverlay = () => {
    if(!displayDeleteOverlay){
      return;
    }
    return(
      <Overlay>
        <div className={styles.deleteZoneContainer}>
           <h2>Delete Plan?</h2>
          <p>Are you sure you want to delete this plan? Type "<b>{props.selectedPlan.plan_name}</b>" to delete zone</p>
          <div className={styles.deleteInput}>
            <TextInput 
              name="Plan Name" 
              value={formData.overlayInput} 
              onChange={(value) => handleChange("overlayInput", value)} 
              labelStyle="top" 
              placeholder="Enter Plan Name"
            />
            </div>
          <Button name={"Delete Plan"} disabled={props.selectedPlan.plan_name === formData.overlayInput ? false : true} color={"Error"} onPress={() => deletePlan()}/>
          <Button name={"Cancel"}  color={"Coal"} onPress={() => setDisplayDeleteOverlay(false)}/>
        </div>
      </Overlay>
    ) 
  }

  const renderHeader = (header, value, headerWidth) => {
    return ( 
      <div className={styles.headerLineContainer}>
        <div className={styles.headerContainer} style={{width: headerWidth}}>
              {header}
              {value && <div className={styles.headerValue}>{value}</div>}
        </div>
      </div>
    );
  };

  const handleChange = (formField, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [formField]: formField === "surfaceArea" && value !== "" && isNaN(value) 
        ? prevData.surfaceArea // Keep previous valid value if input is invalid
        : value, // Otherwise, store new value
    }));
  };

  const deletePlan = async () => {
    try {
      
      const request = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      };

      // Send request
      const response = await fetch(user.backEndURL + "/plans/" + props.selectedPlan._id, request);
      const jsonResponse = await response.json();

      if (jsonResponse.success) {
        setDisplayDeleteOverlay(false);
        props.reloadZones();
        // helpers.successPrompt("Zone deleted successfully.", null, setPrompt, null);
      } else {
        alert("Oops! Something went wrong while deleting the zone.");
        // helpers.errorPrompt("Oops! Something went wrong while creating the zone.", null, setPrompt, null);
      }
    } catch (err) {
      console.error("Error creating zone:", err);
      // helpers.errorPrompt("Unexpected error occurred. Please try again.", null, setPrompt, null);
    }
  };

  const renderSummary = () => {
    if (!props.selectedPlan) return null;
  
    return (
      <div className={styles.summaryContainer}>
        {renderHeader("Summary")}
  
        <div className={helpers.clx(styles.summaryItem, styles.summaryItemDark)}>
          <div className={styles.summaryLabel}>Name</div>
          <div className={styles.summaryValue}>{props.selectedPlan.plan_name || "-"}</div>
        </div>
  
        <div className={styles.summaryItem}>
          <div className={styles.summaryLabel}>Total Area</div>
          <div className={styles.summaryValue}>
            {props.selectedPlan.total_area ? `${props.selectedPlan.total_area.toFixed(2)} ha` : "-"}
          </div>
        </div>
  
        <div className={helpers.clx(styles.summaryItem, styles.summaryItemDark)}>
          <div className={styles.summaryLabel}>Current Avg. Rate</div>
          <div className={styles.summaryValue}>
            {props.selectedPlan.applications? `${props.selectedPlan.average_target_rate.toFixed(2)} t/ha` : "n/a"}
          </div>
        </div>
  
        <div className={styles.summaryItem}>
          <div className={styles.summaryLabel}>Target Avg. Rate</div>
          <div className={styles.summaryValue}>
            {props.selectedPlan.average_target_rate ? `${props.selectedPlan.average_target_rate.toFixed(2)} t/ha` : "-"}
          </div>
        </div>
  
        <div className={helpers.clx(styles.summaryItem, styles.summaryItemDark)}>
          <div className={styles.summaryLabel}>Year</div>
          <div className={styles.summaryValue}>
            {props.selectedPlan.plan_year || "-"}
          </div>
        </div>
      </div>
    );
  };

  const renderBreakdown = () => {
    if (!props.selectedPlan) return null;
    console.log(props.selectedPlan);
    
  
    const totalArea = props.selectedPlan.total_area;
  
    const totalTargetKg = props.selectedPlan.average_target_rate * totalArea * 1000;
    const totalBags = Math.ceil(totalTargetKg / 215);
    const totalLorries = Math.round((totalBags  )/ 2.4)/10;
    console.log(totalArea + " " + totalTargetKg +  " ")

   return <KeyFigures data={[
      {label: "Total Area", value: totalArea.toFixed(2), unit: "ha" },
      {label: "Avg. Target Rate", value: props.selectedPlan.average_target_rate.toFixed(2), unit: "t/ha" },
      {label: "Bags Equiv.", value: totalBags, unit: "bags" },
      {label: "Artic Loads", value: totalLorries}
   ]}/>
  
  };

  // const renderBreakdown = () => {
  //   if (!formData || !zones || zones.length === 0) return null;
  
  //   const selected = selectedZones
  //     .map(index => zones[parseInt(index)])
  //     .filter(Boolean);
  
  //   const totalArea = selected.reduce((sum, zone) => {
  //     return sum + parseFloat(zone.surface_area || 0);
  //   }, 0);
  
  //   const totalTargetKg = selected.reduce((sum, zone) => {
  //     const rate = parseFloat(formData.targetedRates[zone._id] || 0); // ⬅️ FIXED
  //     return sum + rate * parseFloat(zone.surface_area || 0) * 1000;
  //   }, 0);
  
  //   const avgTargetRate = totalArea > 0 ? (totalTargetKg / totalArea / 1000).toFixed(2) : "0";
  //   const totalBags = Math.ceil(totalTargetKg / 215);
  //   const totalLorries = Math.round((totalBags  )/ 2.4)/10;
  
  //   return (
  //     <div className={styles.breakdownRow}>
  //       <div className={styles.breakdownItem}>
  //         <div className={styles.breakdownLabel}>Total Area</div>
  //         <div className={styles.breakdownValue}>{totalArea.toFixed(2)} <span className={styles.unit}>ha</span></div>
  //       </div>
  //       <div className={styles.separatorLine}/>
  
  //       <div className={styles.breakdownItem}>
  //         <div className={styles.breakdownLabel}>Avg. Target Rate</div>
  //         <div className={styles.breakdownValue}>{avgTargetRate} <span className={styles.unit}>t/ha</span></div>
  //       </div>
  //       <div className={styles.separatorLine}/>
  
  //       <div className={styles.breakdownItem}>
  //         <div className={styles.breakdownLabel}>Bags Equiv.</div>
  //         <div className={styles.breakdownValue}>{totalBags} <span className={styles.unit}>bags</span></div>
  //       </div>
  //       <div className={styles.separatorLine}/>
  
  //       <div className={styles.breakdownItem}>
  //         <div className={styles.breakdownLabel}>Artic Loads</div>
  //         <div className={styles.breakdownValue}>~ {totalLorries}</div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className={helpers.clx(styles.container)}>
      {renderOverlay()}
      <div className={helpers.clx("contentGrid", styles.zoneContainer)}>
        <Module icon={'\uf274'} name={"Plan Details"} spanColumn={24} spanRow={1} height={"100%"}>
          <div className={styles.contentContainer}>
            <div className={styles.iconsContainer}>
              {/* <div className={helpers.clx(props.selectedPlan && styles.disabled, styles.editIcon)} onClick={() => setEditingInfo(true)}>
                {editingInfo ? "" : "pencil"}
              </div> */}
              <div className={styles.editIcon} onClick={() => setDisplayDeleteOverlay(true)}>
                {false ? "" : "trash-alt"}   
              </div>
            </div>
            <div className={styles.detailsRow}>
              
              {renderSummary()}
              <div className={styles.graphContainer}>
                <div className={styles.doubleHeaderContainer}>
                  {renderHeader("Bags Applied")}
                  <div  className={styles.headerTotalContainer}>
                  {renderHeader("Total", "0", "10.5rem")}

                  </div>
                </div>
                <div className={styles.graphContent}>
                  <div className={styles.summaryLabel}>No Data To Be Displayed</div>
                </div>
            
              </div>
            </div>
            
            <div className={styles.detailsRow}>
            {/* Applied Rate Gauges */}
              <div className={styles.gaugeContainer}>
              {renderHeader("Current Applied Rate")}

                {props.selectedPlan.targeted_zones.map((zone, index) => {
                  console.log(zone)
                  return(
                  <div key={zone._zone} className={styles.gaugeItem}>
                    {index === 0 ? "" : <div className={styles.separatorLine}/>}
                    <Gauge progress={0} progressMax={zone.targeted_rate} unit={"t/ha"}/>
                    <div className={styles.gaugeLabel}>
                      {zone.field_type ? zone.field_type.charAt(0).toUpperCase() + zone.field_type.slice(1) : ""}
                      <div className={styles.fieldType}><img src={zone.field_type === "grassland" ? iconGrassland : iconArable} className={styles.fieldTypeIcon}/>{zone._zone.zone_name}</div>

                      <div className={styles.targetRate}>{zone.name}Target Rate: <span className={styles.targetValue}>{zone.targeted_rate} t/ha</span></div>
                    </div>
                  </div>
                )})}
              </div>


              {/* Applications List */}
              <div className={styles.applicationsContainer}>
               {renderHeader("Key Figures")}
               {renderBreakdown()}
                {renderHeader("Biochar Applications")}
                <div className={styles.applicationRows}>
                  { props.selectedPlan.applications && props.selectedPlan.applications.map((app, index) => (
                    <div key={index} className={styles.applicationRow}>
                      <div className={styles.applicationId}>{app.id}</div>
                      <div className={styles.applicationBags}>{app.bags} bags</div>
                      <div className={styles.applicationDate}>{app.date}</div>
                      <div className={styles.applicationMethod}>{app.method}</div>
                    </div>
                  ))}
                  {props.selectedPlan.applications && props.selectedPlan.applications.length > 0 ? 
                    props.selectedPlan.applications.map((app, index) => (
                      <ApplicationCard app={app} isPlaceholder={false}/>
                    )) : 
                    <ApplicationCard isPlaceholder={true}/>
                  }
                </div>
              </div>
            </div>
          </div>
        </Module>
      </div>
    </div>
  );
};

export default PlanDetails;