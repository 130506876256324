import { useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";

import styles from './UpdateSubscriptionScreen.module.css';
import helpers from '../../helpers.js';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import Button from "../Button.js"
import Plans from "./Plans.js";
import TextInput from "../TextInput.js";


import PaymentTerms from "./PaymentTerms.js";
import ProductCounter from "./ProductCounter.js";
import Calculator from "./Calculator.js";

import pincharPlaceholder from "../../assets/images/pincharPlaceholder.png"


function UpdateSubscriptionScreen(props) {
  let isFromNextScreen = typeof useLocation().state.formData !== "undefined";
  let nextUseLocationFormData = useLocation().state.formData;

  const [addresses, setAddresses] = useState(useLocation().state.addresses);
  const [selectedProduct, setSelectedProduct] = useState("pinchar");
  const [errorPromptVoucher, setErrorPromptVoucher] = useState("");
  const [highlighedtInputVoucher, setHighlighedtInputVoucher] = useState("");
  
  const [formData, setFormData] = useState(
    typeof useLocation().state.formData === "undefined" ? 
    {
      pincharBags: 0,
      pelletcharBags: 0,
      paymentPlan: "",
      voucherCode: "",
    }
    : 
    nextUseLocationFormData
  );

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };
  
  const updateBiocharQty = (pincharBags, pelletcharBags) => {
    setFormData({ ...formData, pincharBags: pincharBags, pelletcharBags: 0 });
  };

  const calculatePlanDiscount = () => {
    switch(formData.paymentPlan) {
      case "monthly":
        return 0;
      case "quarterly":
        return(formData.pincharBags * 80 + formData.pelletcharBags * 80)/10;
      case "yearly":
        return(formData.pincharBags * 80 + formData.pelletcharBags * 80)/5;
      default:
        return 0;
    }
  }

  const calculateSupervisorDiscount = () => {
    // return Math.round((formData.pincharBags * 80 + formData.pelletcharBags * 80)/6);
    return
  }

  const renderHeader = (header, stepNumber, bgColor) => {
    return (
        <div className={styles.headerContainer}>
            <div className={helpers.clx(styles.hexagon, bgColor === "leaf" ? styles.hexagonLeaf : "")}>
                {stepNumber}
            </div>
            <div className={helpers.clx(styles.headerText, bgColor === "leaf" ? styles.headerTextLeaf : "")}>
              {header}
            </div>
        </div>
    );
  };

  const handleSubmitCode = () => {
    
    //Send code to server
    //If code is valid, set discount
    handleChange("voucherCode", "");
     helpers.errorPrompt("Please enter a valid voucher code", "voucherCode", setErrorPromptVoucher, setHighlighedtInputVoucher)
    

  }

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"subscription"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Bulk Biochar Purchase"} content={"Set up your Black Bull Biochar package and start getting biochar delivered straight to your farm! Packages are made on a yearly basis. You may choose monthly, quarterly, or yearly billing."}/>
          <div className={"contentGrid"}>
            <Module icon={""}  spanColumn={18} spanRow={2} height={"100%"}>
            {renderHeader("How Much Biochar Do You Need Per Year?", 1)}

              <div className={styles.productsContainer}>
                <div className={helpers.clx(styles.productContainer, selectedProduct === "pinchar" ? styles.productContainerSelected : "")}>
                  <ProductCounter selectedProduct={selectedProduct} fieldName={"pincharBags"} value={formData.pincharBags} handleChange={handleChange} name="Pinchar" perBag="~ 230 kg / bag" icon={pincharPlaceholder}/>    
                </div>
                <div className={styles.separatorLine}/>
                <div className={helpers.clx(styles.productContainer, selectedProduct === "chickenchar" ? styles.productContainerSelected : "")}>
                  <ProductCounter selectedProduct={selectedProduct} fieldName={"chickenCharBags"} value={formData.chickencharBags} handleChange={handleChange} name="Chickenchar" perBag="~ 230 kg / bag" icon={pincharPlaceholder}/>    
                </div>
              </div>
           
            </Module>
            <Module spanColumn={6} spanRow={2}>
              {renderHeader("Voucher Code", 4)}
              <div className={styles.voucherContentContainer}>
                <TextInput 
                  name="Voucher Code" 
                  iconName="tags"
                  value={formData.voucherCode} 
                  onChange={(value) => handleChange("voucherCode", value)} 
                  labelStyle="top" 
                  placeholder="Enter Code"
                />
                <Button name={"Enter"} disabled={formData.voucherCode?.length < 1 ? true : false} onPress={handleSubmitCode}/>
                <div className={"errorPrompt"}>
                  {errorPromptVoucher}
                </div>
              </div>
             
            </Module>
           
            <Module spanColumn={18} spanRow={5} height={"100%"}>
            {renderHeader("Select Purchase Option", 2)}

              <div className = {styles.planDescription}>
              Depending on the payment plan you select, you may benefit from a discount. Your payment plan will not affect the frequency of your deliveries.
              </div>
              <Plans handleChange={handleChange} paymentPlan={formData.paymentPlan} selected={"monthly"}/>
            </Module>
            <Module background={"var(--leaf)"} spanColumn={6} spanRow={3} >
              {renderHeader("Storage & Delivery", 5, "leaf")}
              <div className={styles.storageDeliveryContent}>
                <div className={styles.storageDeliveryRow}>
                  <span className={styles.storageDeliveryIcon}>truck</span>
                    <div className={styles.weightText}>
                    ~ <span className={styles.storageDeliveryValue}>{Math.round(helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, "monthly").volumeTotal)} </span>Deliveries 
                    </div>
                </div>
                <div className={styles.storageDeliverySeparatorLine}/>
                <div className={styles.storageDeliveryRow}>
                  <span className={styles.storageDeliveryIcon}>cube</span>
                    <div className={styles.weightText}>
                      You will need at least <span className={styles.weightAmount}>{Math.round(helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, "monthly").volumeTotal)} m</span><span className={styles.weightAmountExp}>2</span>  of floor space
                    </div>
                </div>
                <div className={styles.storageDeliverySeparatorLine}/>
                <div className={styles.storageDeliveryRow}>
                  <span className={styles.storageDeliveryIcon}>pound-sign</span>
                    <div className={styles.weightText}>
                      Get <span className={styles.weightAmount}>20 £</span> back for each bag successfully logged in the BBB app
                    </div>
                </div>
              </div>
            </Module>  
            <Module name={"Annual Price"}spanColumn={6} spanRow={3} >
              <div className={styles.invoiceContainer}>
                <div className={styles.invoiceTable}>
                  <div className={styles.invoiceRow}>
                    <div className={styles.invoiceItem}>Pinchar</div>
                    <div className={styles.invoicePrice}>£ {formData.pincharBags * 80}</div>
                  </div>
                  <div className={helpers.clx(styles.invoiceRow, styles.invoiceRowWhite)}>
                    <div className={styles.invoiceItem}>Pelletchar</div>
                    <div className={styles.invoicePrice}>£ {formData.pelletcharBags * 270}</div>
                  </div>
                  <div className={styles.invoiceRow}>
                    <div className={styles.invoiceItem}>Discount</div>
                    <div className={styles.invoicePrice}>- £ 0{/*calculatePlanDiscount()*/}</div>
                  </div>
                  <div className={helpers.clx(styles.invoiceRow, styles.invoiceRowWhite)}>
                    <div className={styles.invoiceItem}>BBB Discount</div>
                    <div className={styles.invoicePrice}>- £{formData.pincharBags * 80}{calculateSupervisorDiscount()}</div>
                  </div>
                  <div className={styles.invoiceLine}/>
                  <div className={helpers.clx(styles.invoiceRow, styles.invoiceRowBlack)}>
                    <div className={styles.invoiceItemBlack}>TOTAL</div>
                    <div className={styles.invoicePriceBlack}>£ {/*(formData.pelletcharBags * 270 + formData.pincharBags * 80) - calculatePlanDiscount() - calculateSupervisorDiscount()*/0}</div>
                  </div>
                </div>
                <Button name="Next" onPress={() => props.goToScreen("review-subscription", {formData: formData, addresses: addresses})} color="Grass" disabled={formData.paymentPlan === "" || formData.pincharBags === 0 && formData.pelletcharBags === 0 ? true : false}/>
              </div>
              
            </Module>         
            <Module name={"Select Payment Terms"} spanColumn={18} spanRow={1} height={"100%"}>
              <div>
              Depending on the payment plan you select, you may benefit from a discount. Your payment plan will not affect the frequency of your deliveries.
              </div>
              <PaymentTerms handleChange={handleChange} selectedPaymentTerm={formData.selectedPaymentTerm} />
            </Module>
        
                       
   
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSubscriptionScreen;
