import React from 'react';
import helpers from '../../helpers.js';

import styles from './ProductCounter.module.css'; //styles
import Incrementer from '../Incrementer.js';
import Checkbox from '../Checkbox.js';

const ProductCounter = (props) => {
    return(
            <div className={helpers.clx(styles.productCounterContainer, props.disabled ? styles.productCounterContainerDisabled : "")}>
               <div className={styles.headerRow}>
                    <div className={styles.productNameContainer}>
                        <Checkbox text="" checked={props.selectedProduct === props.name.toLowerCase() ?  true : false} onChange={props.handleChange} fieldName={props.fieldName} disabled={props.disabled}/>
                        {props.name}
                    </div>
                    <div className={styles.priceContainer}>
                        {props.perBag}
                    </div>
               </div>
               <div className={styles.separatorLine}/>
               <div className={styles.contentRow}> 
                    <Incrementer increment={12} fieldName={props.fieldName} handleChange={props.handleChange} value={props.value}/>    
                    <div className={styles.volumeWeightContainer}>
                        <div className={styles.volumeWeightRow}>
                            <span className={styles.unitIcon}>cube</span><span className={styles.unitValue}>{Math.round(10 * props.value*2)/10}</span>m<span className={styles.unitExp}>3</span>
                        </div>
                        <div className={styles.volumeWeightRow}>
                            <span className={styles.unitIcon}>balance-scale</span><span className={styles.unitValue}>{Math.round(props.value*23)/100}</span>t
                        </div>

                    </div>
                </div>
            </div>
    )
}

ProductCounter.defaultProps = {
    selected: "",
    disabled: false
  };

export default React.memo(ProductCounter);