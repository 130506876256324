
const mapStyle = [
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text",
        "stylers": [
          { "visibility": "on" },
          { "color": "#ffffff" },   // Adjust label color if needed
          { "lightness": 30 },      // Make labels less intense
          { "weight": "0.5" },      // Reduce font weight
          { "saturation": -100 }    // Desaturate for a softer look
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
          { "color": "#bbbbbb" },   // Softer gray color for labels
          { "lightness": 50 }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.stroke",
        "stylers": [
          { "color": "#000000" },   // Thin black outline for readability
          { "weight": 0.3 }         // Thin stroke
        ]
      },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "labels.text",
      "stylers": [
        { "visibility": "on" },
        { "color": "#cccccc" },
        { "weight": 0.5 }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
        { "visibility": "off" }
      ]
    }
  ];

  export default mapStyle;