import styles from './BagPopup.module.css'; //styles
import helpers from '../../helpers.js';
import { useState } from 'react';

import Selector from "../../components/Selector.js";
import TextInput from "../../components/TextInput.js";
import DateSelector from '../../components/DateSelector.js';
import Checkbox from "../../components/Checkbox.js";
import Button from "../../components/Button.js";
import CharcodeTag from "../../components/CharcodeTag.js";
import selectArrowGrey from "../../assets/images/selectArrowGrey.png";


const eventTypes = [
    {name: 'Order', value: 'order'}, 
    {name: 'Other', value: 'other'}, 
    {name: 'Machine Log', value: 'machineLog'}
];




/*
    EventPopup
        props:
            checked: boolean, sets EventPopup status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before EventPopup 
*/
const BagPopup = (props) => {
    let farmerList = [];
    props.farmers.forEach((farmer) => {
        let newFarmer = {name: farmer.first_name + " " + farmer.last_name, value: farmer.first_name + "-" + farmer.last_name};
        farmerList.push(newFarmer);
    });
    console.log(props.bag)
    const [formData, setFormData] = useState({
        eventType: {name: "Order", value: "order"},
        site: props.bag._site ? props.bag._site.name : "n/a",
        status: props.bag.status,
        date: new Date(),
        time: "00:00",
        farmer:  props.bag._order ? props.bag._order._user.first_name + "-" + props.bag._order._user.last_name : "n/a",
        bags: 0,
        isNewDelivery: true,
        orderId: props.bag._order ? props.bag._order.order_id : "n/a",
        appliedTo: props.bag.applied_to,
        comment: "",
        baggingDate: props.bag.bagging_date ? props.bag.bagging_date.substring(0,10) : "",
        pickupDate: props.bag.locations?.storage_pickup ? props.bag.locations.storage_pickup.time.substring(0,10) : (props.bag.pickup_date ? props.bag.pickup_date.substring(0,10) : ""),
        applicationDate: props.bag.application_date ? props.bag.application_date.substring(0,10) : ""

    });
    const [farmers, setFarmers] = useState(farmerList);
    const [sites, setSites] = useState([
        {name: 'Ahlstrom', value: 'ARA'}, 
        {name: 'Jenkinson', value: 'JNR'},
        {name: 'Test Site', value: 'Test'},

    ]);
    const [bags, setBags] = useState(props.bags)
    const [collapseBags, setCollapseBags] = useState(false);
    // const check = () => {
    //     // setIsChecked((prevIsChecked) => !prevIsChecked);
    //     props.onPress();
    // }

    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    const handleChange = (formField, value) => {
        setFormData({ ...formData, [formField]: value });
      };

    const handleSubmit = () => {

        return;
        
    }

    // const renderCharcodeTags = () => {

    //     return (
    //         <div className={helpers.clx(styles.bagsContainer, collapseBags ? styles.bagsContainerCollapsed : "")}>
    //             {bags.map((bag) => {
    //                 return(<CharcodeTag charcodeId={bag}/>)
    //             })}
    //         </div>
    //     )
    // }

    const renderOverlayContent = () => {
        return (
            <>
                <div className={styles.overlayRow}>
                    <div className={helpers.clx(styles.inputContainer, styles.selectHighZIndex)}>
                        <Selector name="Farmer" value={formData.farmer} data={farmers} onChange={(value) => handleChange("farmer", value)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Country" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                        <Selector name="Status" value={formData.status} data={[{name: "Bagged", value: "bagged"}, {name: "Assigned", value: "assigned"}, {name: "Picked Up", value: "pickedUp"}, {name: "Delivered", value: "delivered"}, {name: "Applied", value: "applied"}]} onChange={(value) => handleChange("status", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Select Country" color="white"/>
                    </div>
                </div>
                <div className={styles.overlayRow}>
                    <div className={styles.inputContainer}>
                        <Selector name="Site" value={formData.site} data={sites} onChange={(value) => handleChange("site", value)} labelStyle="top" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                        <TextInput name="Batch ID" value={props.bag.batch_id} onChange={(value) => handleChange("batchId", value)} labelStyle="top" placeholder="Enter Batch" color="white"/>
                    </div>
                </div>
                <div className={styles.overlayRow}>
                    <div className={styles.inputContainer}>
                        <TextInput name="Moist. Content (%)" value={props.bag.moisture_content} onChange={(value) => handleChange("moistureContent", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Enter Moist. Content" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                        <TextInput name="Bag Temp. (°C)" value={props.bag.internal_temperature} onChange={(value) => handleChange("weight", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Enter Weight" color="white"/>
                    </div>
                </div>
                <div className={styles.overlayRow}>
                    <div className={styles.inputContainer}>
                        <TextInput name="Weight (kg)" value={props.bag.weight} onChange={(value) => handleChange("weight", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Enter Weight" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                       <DateSelector value={formData.baggingDate} name={"Bagging Date"} labelStyle={"top"}/>
                    </div>
                </div>
                <div className={styles.overlayRow}>
                    <div className={helpers.clx(styles.inputContainer)}>
                        <TextInput name="Order ID" value={formData.orderId} data={eventTypes} onChange={(value) => handleChange("orderId", value)} labelStyle="top" placeholder="Order ID" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                      <DateSelector value={formData.pickupDate} name={"Pickup Date"} labelStyle={"top"}/>
                    </div>
                </div>        
                <div className={styles.overlayRow}>
                    <div className={helpers.clx(styles.inputContainer)}>
                        <Selector name="Applied To" value={formData.appliedTo} data={[{name: "Slurry", value: "slurry"}, {name: "Bedding", value: "bedding"}, {name: "FYM", value: "fym"}, {name: "Direct To Land", value: "directToLand"}]} onChange={(value) => handleChange("appliedTo", value)}  labelStyle="top" placeholder="Applied To" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                      <DateSelector value={formData.applicationDate} name={"Application Date"} labelStyle={"top"}/>
                    </div>
                </div> 
                <div className={styles.buttonRow}>
                    <Button disabled={false} onPress={props.closePopup} name="Delete" color="Error"/>
                    <Button disabled={false} onPress={props.closePopup} name="Cancel" color="Coal"/>
                    <Button disabled={false} onPress={handleSubmit} name="Update" color="Leaf"/>
                </div>
            </>
        )
        
    }
    // if(!props.displayEventPopup){
    //     return;
    // }
    console.log(props.bag)
    return(
        <div className={styles.overlayBg}>
            <div className={styles.overlayClosePopup} onClick={() => props.closePopup()}/>
            <div className={styles.overlay}>
                <div className={styles.headerBar}>
                    <div>
                        <span className={styles.headerIcon}>
                         shopping-bag
                        </span>
                         Biochar Bag
                    </div>
                    <div className={styles.headerOrderId}><span className={styles.headerIcon}>qrcode</span>{props.bag.charcode}</div>
                </div>
              <div className={styles.overlayContent}>
                {renderOverlayContent()}
              </div>
            </div>
        </div>
    )
}

BagPopup.defaultProps = {
    thinnerText: false,
    checked: false,
    onPress: console.log("Set EventPopu onPress"),
    text: "Change EventPopu text prop",
    inverted: false,
    closePopup: () => alert("set ClosePopup prop")
  };

export default BagPopup;