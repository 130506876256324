const questionsList = [
    {
      name: "What should I do if I’m logged out or can’t access the app?",
      answer: "Please try logging in again using your credentials. If the issue persists, contact us directly at +44 72 2323 92839 or reach out through the support section of our website."
    },
    {
      name: "How do I add a new field or zone to my account?",
      answer: "Go to the 'Zones' tab, then tap 'Add New Zone'. You can either drop a marker or draw your field directly on the map, then fill in the details like soil type, field type, and area."
    },
    {
      name: "How do I create a new biochar application plan?",
      answer: "Head to 'Plans', tap 'Add New Plan', then select the zones you want to include. You’ll be able to enter your target rates and get a full breakdown before saving the plan."
    },
    {
      name: "What does 'biochar applied' mean?",
      answer: "This shows how much biochar you’ve reported as applied to a zone. If it's showing 0, it means no application has been logged yet. You can update this during the application process."
    },
    {
      name: "Can I update my delivery address or contact info?",
      answer: "Yes. Navigate to your account settings, where you can update your delivery and billing information. For urgent delivery changes, please contact us directly."
    },
    {
      name: "How is my recommended application rate calculated?",
      answer: "We base it on field type: 500 kg/ha for grassland and 1000 kg/ha for arable land. These values help optimize the carbon benefits of biochar while improving soil health."
    },
    {
      name: "What do the different plan colors on the map mean?",
      answer: "Zones in a selected plan are shaded from orange to green based on how close your biochar application is to the target rate. Grey zones are not part of the plan."
    },
    {
      name: "What is the difference between a zone and a field?",
      answer: "A zone is a management unit within a field, used to track applications more precisely. You can assign multiple zones to the same field if needed."
    },
    {
      name: "How do I report a problem or suggest a feature?",
      answer: "We’d love to hear from you. Use the feedback form on the website, or email us directly. Your input helps improve the app for all farmers."
    }
  ];
  
  export default questionsList;