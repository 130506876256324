
import styles from './ZonesScreen.module.css';
import helpers from './helpers.js';
import {useState, useContext, useCallback, useEffect, useRef} from 'react';
import { UserContext } from './UserContext.js';
import { GoogleMap, useJsApiLoader, DrawingManager, Polygon, Marker, InfoWindow } from '@react-google-maps/api';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import TextInput from "./components/TextInput.js";
import Button from "./components/Button.js";
import FAQ from "./components/support/FAQ.js";

import mapStyle from './assets/MapStyle.js';
import iconZoneMarker from './assets/images/iconZoneMarker.png';
import iconZoneMarkerUnselected from './assets/images/iconZoneMarkerUnselected.png';

import ZoneDetails from './components/zones/ZoneDetails.js';
import PlanDetails from './components/zones/PlanDetails.js';

const mapContainerStyle = {
  marginLeft: "0.5rem",
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  boxShadow: "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12)",
  borderRadius: "1.3rem"
};


//54.713801, -2.749613
const center = {
  lat: 54.713801,
  lng: -2.749613
};

const zonesArray = [
  {
    id: "",
    status: "delivered",
    deliveryDate: "",
    pincharBags: "",
    pelletcharBags: "",
    weight: "",
    volume: ""
  }
]

const libraries = ['drawing', 'geometry'];




function ZonesScreen(props) {
  // const {user, setUser} = useContext(UserContext);
  // const [selectedFilter, setSelectedFilter] = useState("all");
  // const [zones, setZones] = useState(zonesArray);



  // const renderZones = () => {
  //   let filteredZones = [];
  //   if(selectedFilter === "all"){
  //     filteredZones = zones;
  //   }else {
  //     filteredZones = zones.filter(function (zone) {
  //       return zone.status === selectedFilter 
  //     }
  //   );
  // }

  // const renderStatus = (status) => {
  //   switch(status){
  //     case "delivered":
  //       return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Delivered</td>
  //     case "upcoming":
  //       return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
  //     case "cancelled":
  //       return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
  //     default:
  //         return <td>N/A</td>
  //   }

  // }
 

//   return(
//     <>
//       {filteredZones.map(zone => {
//         return(
//           <tr className={styles.tableRow}>
//             <td className={styles.dataBold}>#{zone.id}</td>
//             {renderStatus(zone.status)}
//             <td>{zone.deliveryDate}</td>
//             <td><span className={styles.dataBold}>{zone.pincharBags} </span>Bags</td>
//             <td><span className={styles.dataBold}>{zone.pelletcharBags}</span> Bags</td>
//             <td><span className={styles.dataBold}>{zone.weight} </span>tons</td>
//             <td><span className={styles.dataBold}>{zone.volume}</span> m<span className={styles.exp}>3</span></td>
//           </tr>
//         ) 
//       })
//     }
//     </>
//   )
// }
//   return (
//     <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
//       <Nav goToScreen={props.goToScreen} currentScreen={"zones"}/>
//       <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
//         <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
//           <ScreenHeader name={"Zones"} content={"Browse through your biochar zones. Don’t hesitate to contact us if you have any questions!"}/>
//           <div className={"contentGrid"}>
//             <Module spanColumn={24} spanRow={5} height={"100%"}>
//               <div className={styles.headerRow}>
//                 <div onClick={() => setSelectedFilter("all")} className={helpers.clx(styles.headerFilter, selectedFilter === "all" ? styles.headerFilterSelected : "")}>
//                   All
//                 </div>
//                 <div onClick={() => setSelectedFilter("upcoming")} className={helpers.clx(styles.headerFilter, selectedFilter === "upcoming" ? styles.headerFilterSelected : "")}>
//                   Upcoming
//                 </div>
//                 <div onClick={() => setSelectedFilter("delivered")} className={helpers.clx(styles.headerFilter, selectedFilter === "delivered" ? styles.headerFilterSelected : "")}>
//                   Delivered
//                 </div>
//                 <div onClick={() => setSelectedFilter("cancelled")} className={helpers.clx(styles.headerFilter, selectedFilter === "cancelled" ? styles.headerFilterSelected : "")}>
//                   Cancelled
//                 </div>
//               </div>
//               <table className={styles.zoneTable}>
//                 <tr className={styles.zoneHeaderRow}>
//                   <th>Zone ID</th>
//                   <th>Status</th>
//                   <th>Delivery Date</th>
//                   <th>Pinchar</th>
//                   <th>Pelletchar</th>
//                   <th>Weight</th>
//                   <th>Volume</th>
//                 </tr>
//                 {renderZones()}
//               </table>

//             </Module>
//           </div>
//         </div>
//       </div>
//     </div>
//   );


/*

{ isLoaded ? 
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <DrawingManager
          onPolygonComplete={onPolygonComplete}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: ['polygon'],
            },
            polygonOptions: {
              editable: true,
              draggable: true,
            },
          }}
        />
      </GoogleMap>
     : null
    }

*/
const {user, setUser} = useContext(UserContext);

console.log(process.env)
const { isLoaded } = useJsApiLoader({
  id: 'google-map-script',
  googleMapsApiKey:  process.env.NODE_ENV === 'development' ?  "AIzaSyD2ILH8PS-Y_oS_pgFJ97eNCBhO_2srDN4" : "AIzaSyD2ILH8PS-Y_oS_pgFJ97eNCBhO_2srDN4",//process.env.GOOGLE_MAPS_API_KEY,
  libraries,
});
const [map, setMap] = useState(null);
const [zones, setZones] = useState([]);
const [selectedZoneIndex, setSelectedZoneIndex] = useState(null);

const [allPolygons, setAllPolygons] = useState([]);
const [bounds, setBounds] = useState(null);
const [plans, setPlans] = useState([]);

const [selectedPlan, setSelectedPlan] = useState("all");

const polygonRefs = useRef([]);


//FUNCTION HERE



const fetchPlans = async () => {
  try {
    const res = await fetch(`${user.backEndURL}/plans/${user.details.id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    const response = await res.json();
    if (response.success) {
      setPlans(response.data.plans);
      setSelectedPlan("all");
    }
  } catch (err) {
    console.error("Failed to fetch plans", err);
  }
};  


const fetchZones = async () => {
  console.log()
  setSelectedZoneIndex(null)
  const request = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    }
  };
  let response = await fetch(user.backEndURL + "/zones/" + user.details.id, request);
  let jsonResponse = await response.json();
  if (jsonResponse.success) {
    console.log(jsonResponse.data.zones)
    setZones(jsonResponse.data.zones)
    if (map && jsonResponse.data.zones.length > 0) {
      // 🔹 Fit map to all zones on load
      const bounds = new window.google.maps.LatLngBounds();
      jsonResponse.data.zones.forEach(zone => {
        if (zone.center_point?.coordinates?.length === 2) {
          bounds.extend({
            lat: zone.center_point.coordinates[1],
            lng: zone.center_point.coordinates[0]
          });
        }
        if (zone.polygon_shape?.coordinates?.length > 0) {
          zone.polygon_shape.coordinates[0].forEach(coord => {
            bounds.extend({ lat: coord[1], lng: coord[0] });
          });
        }
      });
      setBounds(bounds)
      map.fitBounds(bounds);
      fetchPlans();
    }
  } else {
    console.log("Fetch Subscription Failed...")
  }
  
}

useEffect(() => {
  console.log("Fetching Zones")
  if (!user || !user.details?.id) return; // Ensure user is available before fetching

  

  if (map) {
    fetchZones();
  }

  

},[map, user.details?.id]) 

const onLoad = useCallback((mapInstance) => {
  setMap(mapInstance);
}, []);

const onUnmount = useCallback((mapInstance) => {
  setMap(null);
}, []);


// 🔹 Adjust map when a zone is selected
const handleZoneSelection = (index) => {
  setSelectedZoneIndex(index);
  const selectedZone = zones[index];

  if (selectedZone) {
    if (selectedZone.polygon_shape?.coordinates?.length > 0) {
      // 🔹 Zoom to polygon bounds
      const bounds = new window.google.maps.LatLngBounds();
      selectedZone.polygon_shape.coordinates[0].forEach(coord => {
        bounds.extend({ lat: coord[1], lng: coord[0] });
      });
      map.fitBounds(bounds);
    } else if (selectedZone.center_point?.coordinates?.length === 2) {
      // 🔹 Zoom to marker position with an appropriate field-level zoom
      map.setCenter({
        lat: selectedZone.center_point.coordinates[1],
        lng: selectedZone.center_point.coordinates[0]
      });
      map.setZoom(15); // Adjust field zoom level
    }
  }
};

const renderPlanNav = () => {
  const planOptions = plans.map((plan, index) => ({
    label: plan.plan_name || `See Plan ${index + 1}`,
    index
  }));
  console.log(planOptions)
  
  return(
    <div className={styles.navContainer}>
      
      <div className={styles.plansContainer}>
        <div className={helpers.clx(styles.planOption, selectedPlan === "all" ? styles.planOptionSelected : "")} onClick={() => setSelectedPlan("all")}>
          All Zones
        </div>
        {planOptions.map((option) => (
          <div className={helpers.clx(styles.planOption, selectedPlan === option.index.toString() ? styles.planOptionSelected : "")} onClick={() => selectPlan(option.index.toString())}>    
            {option.label}
          </div>
        ))}
    
        
      </div>
      <div className={helpers.clx(styles.planOption, styles.planOptionNew, zones.length < 1 ? styles.planOptionNewDisabled : "")} onClick={() => props.goToScreen("/new-plan", {zones: zones})}>
          Add New Plan +
        </div>
    </div>
  )
}

const getZonesWithPlanTargetData = (plan) => {
  return zones.map(zone => {
    const match = plan.targeted_zones.find(z => z._zone === zone._id);
    return match
      ? { ...zone, targeted_rate: match.targeted_rate, area: match.area }
      : null;
  }).filter(Boolean); // remove nulls
};


const getZoneColor = (zone) => {
  if (!zone.targeted_rate || !zone.biocharApplied) return "#1B1B1B"; // coalLight
  const applied = parseFloat(zone.biocharApplied);
  const target = parseFloat(zone.targeted_rate);
  const ratio = Math.min(applied / target, 1);

  // Interpolate from coalLight to leafGreen (#B0E000)
  const coalRGB = [27, 27, 27];
  const leafRGB = [176, 224, 0];

  const rgb = coalRGB.map((start, i) => {
    return Math.round(start + (leafRGB[i] - start) * ratio);
  });

  return `rgb(${rgb.join(",")})`;
};

const displayedZones = selectedPlan === "all"
  ? zones
  : getZonesWithPlanTargetData(plans[selectedPlan]);


const fitMapToPlanZones = (plan) => {
  if (!map || !plan?.targeted_zones?.length) return;

  const bounds = new window.google.maps.LatLngBounds();

  plan.targeted_zones.forEach((tz) => {
    const zone = tz._zone || tz; // tz._zone is populated, or just the zone object itself

    if (zone.center_point?.coordinates?.length === 2) {
      bounds.extend({
        lat: zone.center_point.coordinates[1],
        lng: zone.center_point.coordinates[0]
      });
    }

    if (zone.polygon_shape?.coordinates?.length > 0) {
      zone.polygon_shape.coordinates[0].forEach((coord) => {
        bounds.extend({ lat: coord[1], lng: coord[0] });
      });
    }
  });

  map.fitBounds(bounds);
};


const selectPlan = (planIndex) => {
  setSelectedZoneIndex(null);
  setSelectedPlan(planIndex);

  const plan = plans[planIndex];
  if (plan) {
    fitMapToPlanZones(plan);
  }
};


const getZoneRatioColor = (zone) => {
  
  const applied = parseFloat(zone.biocharApplied || 0);
  const target = parseFloat(zone.targeted_rate || 0);
  console.log(!applied || !target)
  if (!applied || !target) return "rgb(255,165,0)"; // fallback: coalLight

  const ratio = Math.min(applied / target, 1);

  const from = [255, 165, 0];  // orange (low applied)
  const to = [176, 224, 0];    // leaf green

  const blend = from.map((start, i) =>
    Math.round(start + (to[i] - start) * ratio)
  );
  console.log(`rgb(${blend.join(",")})`)
  return `rgb(${blend.join(",")})`;
};

const isZoneInSelectedPlan = (zone) => {
  if (selectedPlan === "all") return true;

  const plan = plans[selectedPlan];
  if (!plan?.targeted_zones) return false;

  return plan.targeted_zones.some(z => {
    const planZoneId = z._zone?._id || z._zone; // works if it's populated or just an ID
    return String(planZoneId) === String(zone._id);
  });
};


return(
  <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
  <Nav goToScreen={props.goToScreen} currentScreen={"zones"}/>

  <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
    
    <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
      <ScreenHeader name={"Zones"} content={"Add, edit, and delete the zones you own. These are used to map biochar application to your land"}/>
      
      <div className={helpers.clx("contentGrid", styles.contentGrid)}>
        <div className={styles.newZoneButtonContainer}>
          <Button name={"Add New Zone +"} onPress={() => props.goToScreen("/new-zone", {zones: zones})} color={"Coal"}/>

        </div>
        

          <div className={styles.mapContainer}>
            {renderPlanNav()}
            <div className={styles.mapTabsWrapper}>

            {isLoaded && (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={8}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  styles: mapStyle, 
                  disableDefaultUI: true,
                  draggable: true,
                  zoomControl: true,
                  scrollwheel: true,
                  disableDoubleClickZoom: true,
                  draggableCursor: "crosshair",
                  mapTypeId: "hybrid", // 🔹 Switch to Satellite View
                }}
              >
                {/* 🔹 Loop through zones and render polygons or markers */}
                {zones.map((zone, index) => {
                  const isSelected = index === selectedZoneIndex; // 🔹 Check if this zone is selected
                  const fillColor = getZoneColor(zone);

                  if (zone.polygon_shape?.coordinates?.length > 0) {
                    // 🔹 Convert GeoJSON format to Google Maps Polygon format
                    const polygonPaths = zone.polygon_shape.coordinates[0].map(coord => ({
                      lat: coord[1],  // GeoJSON stores [lng, lat], Google Maps uses {lat, lng}
                      lng: coord[0]
                    }));

                    return (
                      <>
                        <Polygon
                          key={zone._id}
                          paths={polygonPaths}
                          options={{
                            fillColor:
                              selectedPlan === "all"
                                ? (isSelected ? "#B0E000" : "#fff")
                                : isZoneInSelectedPlan(zone)
                                  ? getZoneRatioColor(zone)
                                  : "#E0E0E0",
                            fillOpacity:
                              selectedPlan === "all"
                                ? (isSelected ? 0.8 : 0.14)
                                : isZoneInSelectedPlan(zone)
                                  ? 0.6
                                  : 0.3,
                            strokeColor:
                              selectedPlan === "all"
                                ? (isSelected ? "#B0E000" : "#fff")
                                : isZoneInSelectedPlan(zone)
                                  ? getZoneRatioColor(zone)
                                  : "#BBBBBB",
                            strokeWeight: 2,
                            clickable: true,
                            editable: false,
                            draggable: false
                          }}
                          label={{
                            text: zone.zone_name, // ✅ Set the zone name as the label
                            color: "white", // ✅ Text color
                            fontSize: "12px",
                            fontWeight: "bold",
                            className: styles.markerLabel // ✅ Optional custom CSS class for styling
                          }}
                          onClick={() =>  selectedPlan === "all" && handleZoneSelection(index)} // 🔹 Clicking selects this zone
                        />
                        <Marker
                          position={{
                            lat: zone.center_point.coordinates[1], 
                            lng: zone.center_point.coordinates[0],
                          }}
                          options={{
                            zIndex: isSelected ? 1000 : 1, // ✅ Push selected marker in front
                          }}
                          icon={{
                            url: "", // ✅ Empty URL to hide the default marker icon
                            scaledSize: new window.google.maps.Size(1, 1), // ✅ Makes the marker invisible,
                            labelOrigin: new window.google.maps.Point(1, 15) // ✅ Moves label BELOW the icon

                          }}
                          label={{
                            text: zone.zone_name,
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "bold",
                            className: helpers.clx(styles.polygonLabel, isSelected ? styles.polygonLabelSelected : "" ) // ✅ Optional CSS class for custom styling
                          }}
                          onClick={() => selectedPlan === "all" && handleZoneSelection(index)}
                        />
                      </>
                    );
                  } else if (zone.center_point?.coordinates?.length === 2) {
                    return (
                      <>
                        <Marker
                          key={zone._id}
                          position={{
                            lat: zone.center_point.coordinates[1], 
                            lng: zone.center_point.coordinates[0],
                          }}
                          options={{
                            fillOpacity: isSelected ? 0.6 : 0.14, // 🔹 Highlight selected zone
                            zIndex: isSelected ? 1000 : 1, // ✅ Push selected marker in front
                          }}
                          icon={{
                            url: isSelected ? iconZoneMarker : iconZoneMarkerUnselected,
                            scaledSize: new window.google.maps.Size(65, 65),
                            anchor: new window.google.maps.Point(32.5, 65),
                            labelOrigin: new window.google.maps.Point(32.5, 80) // ✅ Moves label BELOW the icon
                            
                          }}
                          label={{
                            text: zone.zone_name, // ✅ Set the zone name as the label
                            color: "white", // ✅ Text color
                            fontSize: "12px",
                            fontWeight: "bold",
                            className: helpers.clx(styles.polygonLabel, isSelected ? styles.polygonLabelSelected : "" ) // ✅ Optional CSS class for custom styling
                          }}
                          // className={isSelected ? "selectedZoneMarker" : "zoneMarker"} // 🔹 Add CSS class
                          onClick={() => selectedPlan === "all" && handleZoneSelection(index)} // 🔹 Clicking selects this marker
                          className={styles.zoneMarker}
                        
                        > 
                      

                        </Marker>
                        {/* <InfoWindow
                            position={{
                              lat: zone.center_point.coordinates[1],
                              lng: zone.center_point.coordinates[0]
                            }}
                            // onCloseClick={() => setSelectedMarker(null)}
                          >
                            <div className={styles.infoWindow}>
                              {zone.zone_name}
                            </div>
                          </InfoWindow> */}
                      </>
                      
                    );
                  }
                  return null;
                })}
              
              </GoogleMap>
            )}
            </div>
          </div>
        
        <div className={styles.zonesListContainer}>
          <div className={styles.zonesListHeaderRow}>
            <div>Zone Name</div>
            <div>Biochar Applied</div>
          </div>
          {zones.length > 0 ? zones.map((zone, i) => {
            return(
              <div className={helpers.clx(
                styles.zoneRow,
                (i === selectedZoneIndex || (selectedPlan !== "all" && isZoneInSelectedPlan(zone)))
                  ? styles.zoneRowSelected
                  : ""
              )}  onClick={() => selectedPlan === "all" && handleZoneSelection(i)}>
                <div className={styles.zoneName}>{zone.zone_name}</div>
                <div className={styles.zoneBiocharApplied} style={{
                  color:
                    selectedPlan === "all"
                      ? "var(--coalLight)"
                      : isZoneInSelectedPlan(zone)
                        ? getZoneRatioColor(zone)
                        : "#999"
                }}>{zone.biocharApplied} n/a </div>
              </div>
            )
          }) :
          <div className={helpers.clx(styles.zoneRow)}  >
            <div className={styles.placeholderZoneName}>-</div>
            <div className={styles.placeholderZoneName}>-</div>
          </div>
           }
        </div>
        {/* <Module name={"User Details"} spanColumn={10} spanRow={2} height={"100%"}>
          <div/>
        </Module> */}
      </div>
      
        {
          allPolygons.map((polygon, index) => {
            console.log(polygon)
            return(
              <div>{polygon.coordinates.map((coordinates) => {
                return (
                  <div>
                    x: {coordinates.lat}, y: {coordinates.lng}
                  </div>
                )
              })}
              </div>
            );
          })
        }
        {selectedPlan === "all" 
          ? <ZoneDetails selectedZone={zones[selectedZoneIndex]} reloadZones={fetchZones}/> 
          : <><PlanDetails 
            selectedPlan={plans[selectedPlan]}
            reloadZones={fetchZones} 
         /></>
        }

    </div>
    
  </div>
</div>
  )

}

export default ZonesScreen;
