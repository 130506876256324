import styles from './ComingUp.module.css'; //styles
// import helpers from '../helpers.js';
import React, {useState, useEffect } from 'react';

import iconCalendar from "../../assets/images/iconCalendar.png";



/*
    CHECKBOX
        props:
          
*/

const getMonthText = (month) => {
    switch(month){
        case 0: return "JAN";
        case 1: return "FEB";
        case 2: return "MAR";
        case 3: return "APR";
        case 4: return "MAY";
        case 5: return "JUN";
        case 6: return "JUL";
        case 7: return "AUG";
        case 8: return "SEP";
        case 9: return "OCT";
        case 10: return "NOV";
        case 11: return "DEC";
    }
}
const renderEvent = (event) => {
    if(typeof event === "undefined"){
        return;
    }
    let type = typeof event.type !== "undefined" ? event.type : "undefined";
    console.log(type)
    let data = {};
    let dateObj = {};
    let fullYear = "";
    switch(type){
        case "undefined": 
            data.month = "-";
            data.day = "N/A";
            data.icon = "empty-set";
            data.label = "-";
            data.text = "-";
            break;
        case "order": 
            dateObj = new Date(event.date);
            fullYear = dateObj.getFullYear();
            data.month = `${getMonthText(dateObj.getMonth())} ${String(fullYear).slice(-2)}`;
            data.day = new Date(event.date).getDate();
            data.icon = "truck";
            data.label = "Biochar Delivery"
            data.text = event.pincharBags + " bags Pinchar"
            break;
        case "invoice": 
            dateObj = new Date(event.date);
            fullYear = dateObj.getFullYear();
            data.month = `${getMonthText(dateObj.getMonth())} '${String(fullYear).slice(-2)}`;
            data.day = new Date(event.date).getDate();
            data.icon = "receipt";
            data.label = "Invoice Due";
            data.text = "£ " + event.amountDue;
            break;
    }
    return(
        <div className={styles.eventRow}>
                    <div className={styles.calendarIconContainer}>
                        <div className={styles.calendarMonth}>
                            {data.month}
                        </div>
                        <div className={styles.calendarDay}>
                            {data.day}
                        </div>
                        <img className={styles.calendarIcon} src={iconCalendar}/>
                    </div>
                    <div className={styles.eventInfoIdContainer}>
                        <div className={styles.eventInfoContainer}>
                            <div className={styles.eventHeader}>
                                <span className={styles.eventIcon}>{data.icon}</span> {data.label}
                            </div>
                            <div className={styles.eventContent}>
                                {data.text}
                                {/* 20 bags Pinchar <br/>
                                11 bags Pelletchar */}
                            </div>
                        </div>
                        <div className={styles.eventIdContainer}>
                            {/* # */}
                        </div>
                        <div className={styles.eventSeparatorLine}/>
                    </div>
                </div>
    )
}

const ComingUp = (props) => {

    return(
        <div className={styles.comingUpContainer}>
            <div className={styles.rowsContainer}>
                <div className={styles.lineCalendar}/>
                {renderEvent(props.events[0])}
                {renderEvent(props.events[1])}

                {renderEvent(props.events[2])}

                {renderEvent(props.events[3])}

                {renderEvent(props.events[4])}

            </div>
        </div>
    )
}


export default  React.memo(ComingUp);
