
import styles from './KeyFigures.module.css'; // your CSS module
import helpers from '../helpers.js';

const KeyFigures = ({ data }) => {
  if (!data || !Array.isArray(data)) return null;

  return (
    <div className={styles.breakdownRow}>
      {data.map((item, index) => (
        <>
            <div key={index} className={styles.breakdownItem}>
            <div className={styles.breakdownLabel}>{item.label}</div>
            <div className={styles.breakdownValue}>
                {item.prefix ? item.prefix : ""}
                {item.value}
                {item.unit && <span className={styles.unit}> {item.unit}</span>}
            </div>
            </div>
            {index < data.length - 1 && <div className={styles.separatorLine} />}
        </>
      ))}
    </div>
  );
};

KeyFigures.defaultProps = {
  data: [],
};

export default KeyFigures;
