import React from 'react';
import styles from './PaymentTerms.module.css'; //styles
import Button from '../Button.js'; //styles
import Checkbox from '../Checkbox.js';
import helpers from '../../helpers.js';

import checkIcon from '../../assets/images/check.png'; //styles

const perksTier1 = [
    "Delivered to your farm in 2m³ bags longer text here",
    "Tracked in our carbon platform",

  ];

const perksTier2 = [
    "Delivered to your farm in 2m³ bags",
    "Tracked in our carbon platform",
    "Access to support and advice",
    "Access to support and advice",
];

const perksTier3 = [
    "Delivered to your farm in 2m³ bags",
    "Tracked in our carbon platform",
    "Access to support and advice",
    "Access to support and advice",
    "Access to support and advice",
    "Access to support and advice",
  ];

const renderPerks = (perks) => {
    return perks.map((perk, index) => (
        <div key={index} className={styles.perkRow}>
            <img src={checkIcon} alt="check" className={styles.checkIcon}/>
            <div className={styles.perkText}>
                {perk}
            </div>
        </div>
    ));
};

const PaymentTerms = (props) => {
    return(
            <div className={styles.paymentTermsContainer}>
               <div className={styles.paymentTermContainer} onClick={() => props.handleChange("selectedPaymentTerm", "monthly")}>
                  <div className={styles.hexagon}>
                    -0%
                  </div>
                  <div className={styles.paymentTermNamePrice}>
                    <Checkbox text="" checked={props.selectedPaymentTerm === "monthly" ?  true : false} onChange={props.handleChange} fieldName="paymentTerm" disabled={props.disabled}/>
                     Monthly
                  </div>
                  <div className={styles.lineSeparator}/>
                  <div className={styles.planDescription}>
                    You receive an invoice at the end of each month. Your first invoice will be due in a month.
                  </div>
                   
               </div>
               <div className={styles.paymentTermContainer} onClick={() => props.handleChange("selectedPaymentTerm", "quarterly")}>
                  <div className={styles.hexagon}>
                    -5%
                  </div>
                  <div className={styles.paymentTermNamePrice}>
                    <Checkbox text="" checked={props.selectedPaymentTerm === "quarterly" ?  true : false} onChange={props.handleChange} fieldName="paymentTerm" disabled={props.disabled}/>
                     Quarterly
                  </div>
                  <div className={styles.lineSeparator}/>
                  <div className={styles.planDescription}>
                    You receive an invoice at the end of each month. Your first invoice will be due in a month.
                  </div>
                   
               </div>
               <div className={styles.paymentTermContainer} onClick={() => props.handleChange("selectedPaymentTerm", "upfront")}>
                  <div className={styles.hexagon}>
                    -10%
                  </div>
                  <div className={styles.paymentTermNamePrice}>
                    <Checkbox text="" checked={props.selectedPaymentTerm === "upfront" ?  true : false} onChange={props.handleChange} fieldName="paymentTerm" disabled={props.disabled}/>
                     Upfront
                  </div>
                  <div className={styles.lineSeparator}/>
                  <div className={styles.planDescription}>
                    You receive an invoice at the end of each month. Your first invoice will be due in a month.
                  </div>
                   
               </div>

            </div>
    )
}

PaymentTerms.defaultProps = {
    selected: ""
  };

export default React.memo(PaymentTerms);