
import styles from './NewZoneScreen.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useCallback, useEffect, useRef} from 'react';
import { useLocation} from "react-router-dom"; //packages

import { UserContext } from '../../UserContext.js';
import { GoogleMap, useJsApiLoader, DrawingManager, Polygon, Marker } from '@react-google-maps/api';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import TextInput from "../TextInput.js";
import Selector from "../../components/Selector";
import Checkbox from '../Checkbox.js';

import Button from "../Button.js";
import FAQ from "../support/FAQ.js";

import mapStyle from '../../assets/MapStyle.js';
import iconZoneMarker from '../../assets/images/iconZoneMarker.png';

import ToggleSwitch from '../ToggleSwitch.js';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
  borderRadius: '20px',
  boxShadow: "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0px 4px 0.6rem rgba(0, 0, 0, 0.12)",
  borderRadius: "1.3rem"
};




//54.713801, -2.749613
const center = {
  lat: 54.713801,
  lng: -2.749613
};

const zonesArray = [
  {
    id: "",
    status: "delivered",
    deliveryDate: "",
    pincharBags: "",
    pelletcharBags: "",
    weight: "",
    volume: ""
  }
]

const soilTypeData = [
  { name: "-", value: "" },  // Default empty option
  { name: "Clay", value: "clay" },
  { name: "Sandy", value: "sandy" },
  { name: "Loamy", value: "loamy" },
  { name: "Other", value: "other" }
];

const fieldTypeData = [
  { name: "Arable", value: "arable" },
  { name: "Grassland", value: "grassland" },
  { name: "Woodland", value: "woodland" },
  { name: "Other", value: "other" }
];

const libraries = ['drawing', 'geometry'];




function NewZoneScreen(props) {
 
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:  process.env.NODE_ENV === 'development' ?  "AIzaSyD2ILH8PS-Y_oS_pgFJ97eNCBhO_2srDN4" : "AIzaSyD2ILH8PS-Y_oS_pgFJ97eNCBhO_2srDN4",//process.env.GOOGLE_MAPS_API_KEY,
    libraries,
  });

const {user, setUser} = useContext(UserContext);
const [map, setMap] = useState(null);
const [allPolygons, setAllPolygons] = useState([]);

const [drawZoneBoundary, setDrawZoneBoundary] = useState(true);// IF TRUE THEN DRAWING POLYGON RATHER THAN SETTING A CENTER POINT
const [zoneGeometry, setZoneGeometry] = useState({});
const markerRef = useRef(null); // Store single marker reference
const polygonRef = useRef(null);
const [existingZones, setExistingZones] = useState([]); // 🔹 Store previous zones from account
const [formData, setFormData] = useState({
  isCultivating: false
});

const location = useLocation();
const params = location.state || {};

const handleChange = (formField, value) => {
  setFormData((prevData) => ({
    ...prevData,
    [formField]: formField === "surfaceArea" && value !== "" && isNaN(value) 
      ? prevData.surfaceArea // Keep previous valid value if input is invalid
      : value, // Otherwise, store new value
  }));
};


const onLoad = useCallback((mapInstance) => {
  setMap(mapInstance);
  setTimeout(() => {
    console.log(map)
    console.log(params.zones)

    if (mapInstance && params.zones.length > 0) {
      console.log(params.zones)
      // 🔹 Fit map to all zones on load
      const bounds = new window.google.maps.LatLngBounds();
      params.zones.forEach(zone => {
        if (zone.center_point?.coordinates?.length === 2) {
          bounds.extend({
            lat: zone.center_point.coordinates[1],
            lng: zone.center_point.coordinates[0]
          });
        }
        if (zone.polygon_shape?.coordinates?.length > 0) {
          zone.polygon_shape.coordinates[0].forEach(coord => {
            bounds.extend({ lat: coord[1], lng: coord[0] });
          });
        }
      });
      mapInstance.fitBounds(bounds);
    }
  }, 100)
}, []);

const onUnmount = useCallback((mapInstance) => {
  setMap(null);
}, []);


const onPolygonComplete = (polygon) => {
  if (polygonRef.current || markerRef.current) {
    polygonRef.current?.setMap(null); // Remove previous polygon
    markerRef.current?.setMap(null); // Correctly remove the previous marker
  }
  polygonRef.current = polygon; // Store new polygon reference

  const path = polygon.getPath();
  const coordinates = path.getArray().map(coord => [coord.lng(), coord.lat()]); // 🔹 Store in GeoJSON format [lng, lat]

  setZoneGeometry({ polygon_shape: { type: "Polygon", coordinates: [coordinates] } });

  
  // Calculate area in square meters
  const googlePath = path.getArray(); // This is an array of google.maps.LatLng
  const areaSqMeters = window.google.maps.geometry.spherical.computeArea(googlePath);
  const areaHectares = (areaSqMeters / 10000).toFixed(2); // Round to 2 decimal places

  setFormData((prevData) => ({
    ...prevData,
    surfaceArea: areaHectares
  }));


  const newPolygon = {
    name: "N/A",
    coordinates: coordinates
  }
  polygon.addListener('mouseover', () => {
    map.setOptions({ draggableCursor: 'pointer' }); // Change cursor to pointer
  });

  polygon.addListener('mouseout', () => {
    map.setOptions({ draggableCursor: 'crosshair' }); // Revert to default cursor
  });

  setAllPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
  polygonRef.current.push(polygon)//newPolygon.coordinates);

  // Add a right-click listener to each polygon to remove it
  window.google.maps.event.addListener(polygon, 'rightclick', () => {
    polygon.setMap(null); // Remove from map
    polygonRef.current = polygonRef.current.filter(p => p !== polygon);
  });



  // Add event listeners for editing vertices
  window.google.maps.event.addListener(path, 'set_at', () => {
    console.log('Vertex moved');
    // Handle vertex move event
  });

  window.google.maps.event.addListener(path, 'insert_at', () => {
    console.log('Vertex added');
    // Handle vertex add event
  });
  

};

const onMarkerComplete = (newMarker) => {
  // 🔹 Ensure only one marker exists at a time
  if (polygonRef.current || markerRef.current) {
    polygonRef.current?.setMap(null); // Remove previous polygon
    markerRef.current?.setMap(null); // Correctly remove the previous marker
  }

  markerRef.current = newMarker; // 🔹 Store the new marker reference
  setZoneGeometry({ 
    lat: newMarker.getPosition().lat(), 
    lng: newMarker.getPosition().lng() 
  });

  // 🔹 Allow clicking on the marker to remove it
  newMarker.addListener('click', () => {
    newMarker.setMap(null);
    markerRef.current = null; // Clear marker reference
    setZoneGeometry({});
  });
};

const resetGeometry = () => {
  // 🔹 Clear polygons
  polygonRef.current?.setMap(null);
  polygonRef.current = null;
  setAllPolygons([]);

  // 🔹 Clear marker properly
    markerRef.current?.setMap(null);
    markerRef.current = null;
  

  setZoneGeometry({});
}

const removeLastAction = () => {
  const polygon = polygonRef.current;
  if (polygon) {
    const path = polygon.getPath();
    if (path.getLength() > 1) {
      path.pop(); // Remove last vertex
    }
  }
};

const handleSubmit = async () => {
  try {
    // Validate required fields
    if (!user.id || !formData.zoneName) {
      // return helpers.errorPrompt("Zone Name is required.", null, setPrompt, null);
    }


    let finalCenterPoint = null;
    let finalPolygonShape = null;

    // 🔹 Extract Polygon Data (if it exists)
    if (polygonRef.current) {
      const path = polygonRef.current.getPath();
      const coordinates = path.getArray().map(coord => [coord.lng(), coord.lat()]);

      // Ensure the polygon closes by appending the first coordinate to the end
      if (coordinates.length > 0) {
        coordinates.push(coordinates[0]); // Append the first point at the end
      }
      finalPolygonShape = {
        type: "Polygon",
        coordinates: [coordinates], // GeoJSON requires an array of linear rings
      };

      // 🔹 Backend will calculate the centroid, so set type to "extrapolated"
      finalCenterPoint = { type: "extrapolated", coordinates: [] };
    }

    // 🔹 Extract Marker Data (if it exists)
    if (markerRef.current) {
      const position = markerRef.current.getPosition();
      finalCenterPoint = {
        type: "manually_set",
        coordinates: [position.lng(), position.lat()], // GeoJSON format: [lng, lat]
      };
    }

    // 🔹 Ensure at least one geometry type exists
    if (!finalCenterPoint && !finalPolygonShape) {
      alert("Please add a polygon or marker to your zone");
      return;
      // return helpers.errorPrompt("Either a Zone Boundary or a Center Point must be provided.", null, setPrompt, null);
    }

    console.log(formData)
    if(!formData.zoneName?.length > 0){
      alert("Please add a zone name");
      return;
    }

    if(!formData.soilType?.length > 0){
      // alert("Please add soil type");
      // return;
    }

    if(!formData.fieldType?.length > 0){
      alert("Please add field type");
      return;
    }


    // 🔹 Construct request body to match backend schema
    const requestBody = {
      _user: user.details.id,
      zone_name: formData.zoneName,
      field_name: formData.fieldName || formData.zoneName, // Default to zone name if empty
      field_id: formData.fieldId,
      center_point: finalCenterPoint, // Either manually_set or extrapolated
      polygon_shape: finalPolygonShape || null, // Send null if empty
      surface_area: formData.surfaceArea || null,
      soil_type: formData.soilType || null,
      field_type: formData.fieldType || null,
      cultivated_in_next_3_years: formData.isCultivating
    };

    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify(requestBody),
    };

    // Send request
    const response = await fetch(user.backEndURL + "/zones", request);
    const jsonResponse = await response.json();

    if (jsonResponse.success) {
      props.goToScreen("zones"); // Redirect on success
    } else {
      // helpers.errorPrompt("Oops! Something went wrong while creating the zone.", null, setPrompt, null);
    }
  } catch (err) {
    console.error("Error creating zone:", err);
    // helpers.errorPrompt("Unexpected error occurred. Please try again.", null, setPrompt, null);
  }
};

return(
  <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
  <Nav goToScreen={props.goToScreen} currentScreen={"zones"}/>

  <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
    
    <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
      <ScreenHeader name={"Add New Zone"} content={"Add, edit, and delete the zones you own. These are used to map biochar application to your land"}/>
      
      <div className={helpers.clx("contentGrid", styles.contentGrid)}>
        {/* <div className={styles.newZoneButtonContainer}>
          <Button name={"Add New Zone +"} onPress={() => props.goToScreen("/new-zone")} color={"Coal"}/>
        </div> */}
        <Module icon={'\uf5cb'} name={"Zone Geometry"} spanColumn={24} height={"100%"}>
          <div className={styles.geometrySettingsContainer}>
            <div className={styles.toggleSwitchContainer}>
              Draw Zone Boundary
              <ToggleSwitch toggled={!drawZoneBoundary} onPress={() => setDrawZoneBoundary(!drawZoneBoundary)}/>
              Set Zone Center
            </div>
            <div className={styles.backButtonContainer}>
            </div>
            <div className={styles.resetButtonContainer}>
            <Button name={"Back"} onPress={() => props.goToScreen("zones")} color={"Coal"} customStyle={{height: "6px !important"}}/>

              <Button name={"Reset ↺"} onPress={() => resetGeometry({})} color={"Error"} customStyle={{height: "6px !important"}}/>
              <Button name={"⌫"} onPress={() => removeLastAction()} color={"Coal"}/>

            </div>
          </div>
          { isLoaded ? 
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={8}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                styles: mapStyle,
                disableDefaultUI: true,
                draggable: true,
                zoomControl: true,
                scrollwheel: true,
                disableDoubleClickZoom: true,
                draggableCursor: "crosshair",
                mapTypeId: "hybrid",
              }}
            > 
              <div>

              </div>

               <DrawingManager
                drawingMode={drawZoneBoundary ? "polygon" : "marker"} // Force the polygon drawing tool to be active
                onPolygonComplete={onPolygonComplete}
                onMarkerComplete={onMarkerComplete}
                options={{
                  drawingControl: false,
                  polygonOptions: {
                    strokeColor:  '#B0E000', //'#34B61F',      // border) color
                    strokeWeight: 2,             // Stroke width in pixels
                    strokePattern: [10, 10],     // 10px dash, 10px gap                  strokeOpacity: 0.8,          // Stroke opacity (transparency)
                    fillColor: '#B0E000',        // fill color
                    fillOpacity: 0.15,           // Fill opacity (transparency)
                    editable: true,
                    draggable: false,
                  },
                  markerOptions: { // 🔹 Custom marker icon setup
                    icon: {
                      url: iconZoneMarker, // 🔹 Path to your custom marker image
                      scaledSize: new window.google.maps.Size(60, 60), // 🔹 Adjust size
                      anchor: new window.google.maps.Point(20, 40), // 🔹 Adjust anchor point (so it sits correctly)
                    },
                  }
                }}
                
              />
              {/* Render existing zones */}
              {params.zones?.map((zone, index) => {
                if (zone.polygon_shape?.coordinates?.length > 0) {
                  // Convert GeoJSON format to Google Maps Polygon format
                  const polygonPaths = zone.polygon_shape.coordinates[0].map(coord => ({
                    lat: coord[1],  // GeoJSON stores [lng, lat], Google Maps uses {lat, lng}
                    lng: coord[0]
                  }));

                  return (
                    <>
                      {/* Polygon */}
                      <Polygon
                        key={`polygon-${zone._id}`}
                        paths={polygonPaths}
                        options={{
                          fillColor: "#B0E000", // ✅ Your requested color
                          fillOpacity: 0.14, // 🔹 Adjust opacity for unselected
                          strokeColor: "#B0E000",
                          strokeOpacity: 0.5, 
                          strokeWeight: 2,
                          clickable: true,
                          editable: false,
                          draggable: false,
                        }}
                      />
                      
                      {/* Polygon Label */}
                      <Marker
                        key={`polygon-label-${zone._id}`}
                        position={polygonPaths[0]} // First coordinate as label anchor
                        icon={{
                          url: "", // ✅ Hide default icon
                          scaledSize: new window.google.maps.Size(1, 1),
                          labelOrigin: new window.google.maps.Point(1, 15) // ✅ Adjust label position
                        }}
                        label={{
                          text: zone.zone_name, // ✅ Display the zone name
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                          className: styles.polygonLabel
                        }}
                      />
                    </>
                  );
                } else if (zone.center_point?.coordinates?.length === 2) {
                  return (
                    <>
                      {/* Marker */}
                      <Marker
                        key={`marker-${zone._id}`}
                        position={{
                          lat: zone.center_point.coordinates[1], 
                          lng: zone.center_point.coordinates[0],
                        }}
                        icon={{
                          url: iconZoneMarker,
                          scaledSize: new window.google.maps.Size(65, 65),
                          anchor: new window.google.maps.Point(32.5, 65),
                          labelOrigin: new window.google.maps.Point(32.5, 80) // ✅ Moves label BELOW the icon
                        }}
                        label={{
                          text: zone.zone_name,
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                          className: styles.markerLabel
                        }}
                      />
                    </>
                  );
                }
                return null;
              })}
            </GoogleMap>
          : null
          }
        </Module>
        <Module icon={"pencil"} name={"Zone Details"} spanColumn={24} spanRow={1} height={"100%"}>
        <div className={styles.contentWrapper}>
          <div className={styles.detailsRow}>
            <TextInput 
              name="Zone Name*" 
              value={formData.zoneName} 
              onChange={(value) => handleChange("zoneName", value)} 
              labelStyle="top" 
              placeholder="Enter Zone Name"
            />
            <TextInput 
              name="Zone ID" 
              value={formData.zoneId} 
              onChange={(value) => handleChange("zoneId", value)} 
              labelStyle="top" 
              placeholder="Enter Zone ID"
            />
          </div>
          
          <div className={styles.detailsRow}>
            <TextInput 
              name="Field Name" 
              value={formData.fieldName} 
              onChange={(value) => handleChange("fieldName", value)} 
              labelStyle="top" 
              placeholder="Enter Field Name"
            />
            <TextInput 
              name="RPA/RLR Field Number" 
              value={formData.fieldId} 
              onChange={(value) => handleChange("fieldId", value)} 
              labelStyle="top" 
              placeholder="Enter Field Number"
            />
          </div>

          <div className={styles.detailsRow}>
            <TextInput 
              name="Surface Area (ha)" 
              value={formData.surfaceArea} 
              onChange={(value) => handleChange("surfaceArea", value)} 
              labelStyle="top" 
              placeholder="Enter Surface Area"
            /> 
            <Selector
              name="Field Type*"
              value={formData.fieldType}
              onChange={(value) => handleChange("fieldType", value)}
              labelStyle="top"
              data={fieldTypeData} // Matches schema enum
            />
                                
          </div>

          <div className={styles.detailsRow}>
            <div className={styles.checkBoxContainer}>
              <Checkbox text={"This zone will be cultivated in the next 3 years"} checked={formData.isCultivating} onPress={() => handleChange("isCultivating", !formData.isCultivating)}/>
            </div>
            {/* <div className={styles.checkBoxContainer}>
              <Checkbox text={"This zone will be cultivated in the next 3 years"} checked={formData.isCultivating} onPress={() => handleChange("isCultivating", !formData.isCultivating)}/>
            </div>                          */}
          </div>

          <div className={styles.buttonRow}>
            <Button onPress={() => props.goToScreen("zones")} name={"Cancel"} color="Coal"/>
            <Button onPress={() => handleSubmit()} name={"Save Zone"} color="Leaf"/>
          </div>
        </div>
        </Module>
        {/* <Button onPress={() => setAllPolygons([])}/> */}
        {/* <Module name={"User Details"} spanColumn={10} spanRow={2} height={"100%"}>
          <div/>
        </Module> */}
      </div>
       
    </div>
  </div>
</div>
  )

}

export default NewZoneScreen;
